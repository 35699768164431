/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */

export function DocumentIcon() {
  return (
    <svg
      height='24px'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 22.0009H6C4.89543 22.0009 4 21.1054 4 20.0009V4.00087C4 2.8963 4.89543 2.00087 6 2.00087H13C13.009 1.99971 13.018 1.99971 13.027 2.00087H13.033C13.0424 2.00383 13.0522 2.00584 13.062 2.00687C13.1502 2.01252 13.2373 2.02967 13.321 2.05787H13.336H13.351H13.363C13.3815 2.0708 13.3988 2.08518 13.415 2.10087C13.5239 2.1493 13.6232 2.21706 13.708 2.30087L19.708 8.30087C19.7918 8.38566 19.8596 8.48493 19.908 8.59387C19.917 8.61587 19.924 8.63687 19.931 8.65987L19.941 8.68787C19.9689 8.77126 19.9854 8.85805 19.99 8.94587C19.9909 8.95583 19.9932 8.96561 19.997 8.97487V8.98087C19.9986 8.98743 19.9996 8.99412 20 9.00087V20.0009C20 20.5313 19.7893 21.04 19.4142 21.4151C19.0391 21.7902 18.5304 22.0009 18 22.0009ZM13 4.00087V9.00087H18L13 4.00087Z'
        fill='#58A4B0'
      />
    </svg>
  );
}
