import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import WithEditPrivilege from 'src/components/wrappers/withEditPrivilege';
import useAuthStore from 'src/store/AuthStore';
import { ROUTES } from 'src/app/config/constants';
import parseImageURL from 'src/utils/image-utils';

import { FaUserAlt } from 'react-icons/fa';
import LifeAzineLogo from 'src/theme/icons/LifeAzineLogo';

import {
  Menu,
  NavContainer,
  NavigationBar,
  NavigationListItem,
  NavigationProfile,
  NavigationProfileIcon,
  NavigationProfileName,
  NavLoginButton,
  ProfilePictureWrapper,
} from './styled/NavBarComponents.styled';
import { useGetProfilePicture } from 'src/graphql/hooks/imageHooks';
import defaultTheme from 'src/theme/defaultTheme';

export default function NavBar() {
  const { isAuthenticated, firstName, userEmail, lastName, userID, userToken } =
    useAuthStore();
  const { push } = useHistory();
  const { data } = useGetProfilePicture(userID, userToken, isAuthenticated);

  return (
    <NavigationBar>
      <NavContainer wide={isAuthenticated}>
        <Link to={ROUTES.overview}>
          <LifeAzineLogo width={89} height={89} color={defaultTheme.colors.mountbatten}/>
        </Link>
        <Menu>
          <NavigationProfile>
            {isAuthenticated ? (
              <>
                <WithEditPrivilege>
                  {/* <NavigationListItem to='/my-magazine'>My Magazine</NavigationListItem> */}
                  <NavigationListItem to={ROUTES.layoutList}>
                    Preset Creator
                  </NavigationListItem>
                </WithEditPrivilege>

                <NavigationListItem to={ROUTES.myProfile}>
                  {firstName ? (
                    <NavigationProfileName>
                      {firstName} {lastName}
                    </NavigationProfileName>
                  ) : (
                    <NavigationProfileName>{userEmail}</NavigationProfileName>
                  )}

                  {data?.profilePicture ? (
                    <ProfilePictureWrapper>
                      <img
                        src={parseImageURL(data?.profilePicture?.url)}
                        alt='navbar-profile-pic'
                      />
                    </ProfilePictureWrapper>
                  ) : (
                    <NavigationProfileIcon>
                      <FaUserAlt />
                    </NavigationProfileIcon>
                  )}
                </NavigationListItem>
              </>
            ) : (
              <>
                <NavLoginButton onClick={() => push(ROUTES.login)}>Login</NavLoginButton>
              </>
            )}
          </NavigationProfile>
        </Menu>
      </NavContainer>
    </NavigationBar>
  );
}
