import React from 'react';
import Typography from 'src/components/presetEditor/pageEditor/ImageUpload/typography/Typography';
import LifeAzineLogo from 'src/theme/icons/LifeAzineLogo';
import { SmallResolutionWarningContainer } from './styled';

const SmallResultionWarning: React.FC = () => {
  return (
    <SmallResolutionWarningContainer>
      <Typography bold>
        Your screen resolution is below the minimum required 1024 x 768. Please increase
        your screen resolution or adjust the DPI scaling setting in your Display
        preference to a smaller size.
      </Typography>
      <LifeAzineLogo />
    </SmallResolutionWarningContainer>
  );
};

export default SmallResultionWarning;
