/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function EditIconWithEllipse() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#60505E"/>
            <path d="M10.6611 21.9967C10.4764 21.9964 10.3003 21.9185 10.1758 21.7821C10.049 21.6467 9.986 21.4637 10.0026 21.279L10.1639 19.5051L17.6164 12.0553L19.9454 14.3836L12.4949 21.8328L10.721 21.9941C10.7006 21.9961 10.6802 21.9967 10.6611 21.9967ZM20.4103 13.918L18.082 11.5897L19.4786 10.1931C19.6021 10.0695 19.7697 10 19.9444 10C20.1192 10 20.2868 10.0695 20.4103 10.1931L21.8069 11.5897C21.9305 11.7132 22 11.8808 22 12.0556C22 12.2303 21.9305 12.3979 21.8069 12.5214L20.4109 13.9174L20.4103 13.918Z" fill="white"/>
        </svg>
    )
}
