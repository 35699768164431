/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function CollapsIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1434 21.8803C15.1434 22.3526 15.537 22.7463 16.0094 22.7463L26.2168 22.7725C26.7154 22.7463 27.109 22.3526 27.109 21.8803V20.6733C27.109 20.2009 26.7154 19.8073 26.2168 19.7811L18.1086 19.7549V11.6728C18.0823 11.1743 17.6887 10.7807 17.2164 10.7807H16.0094C15.537 10.7807 15.1434 11.1743 15.1172 11.6728L15.1434 21.8803Z"
        fill="#51404C"
      />
      <path d="M17.0005 21L26.0007 11.5" stroke="#51404C" strokeWidth="3" />
      <path
        d="M29.7505 21H34.0005V4H17.0005V8.25"
        stroke="#51404C"
        strokeWidth="3"
      />
    </svg>
  );
}
