import { gql } from 'graphql-request';

export const CreateChapter = gql`
  mutation ($input: createChapterInput) {
    createChapter(input: $input) {
      chapter {
        id
        title
        chapterSplash {
          url
        }
        pages {
          id
        }
      }
    }
  }
`;
