import { gql } from 'graphql-request';

export const GetStockPhotos = gql`
  query {
    stockPhotos {
      id
      file {
        id
        url
        name
        formats
      }
    }
  }
`;

export const GetUserGallery = gql`
  query GetUserGallery($userID: ID!) {
    user(id: $userID) {
      id
      privateGallery {
        id
        url
        name
        formats
      }
    }
  }
`;

export const GetAdminImages = gql`
  query {
    adminImages {
      id
      file {
        id
        url
        name
      }
    }
  }
`;
