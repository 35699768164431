/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function NextIconEllipse() {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d)'>
        <circle cx='30' cy='26' r='10' transform='rotate(-180 30 26)' fill='#51404C' />
        <path
          d='M34.7021 26.2869L28.6921 20.2769L27.2771 21.6909L31.8771 26.2909L27.2771 30.8909L28.6921 32.2979L34.7021 26.2869Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='0'
          y='0'
          width='60'
          height='60'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='10' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
