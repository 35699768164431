import React from 'react';
import { RotateSpinner } from 'react-spinners-kit';
import styled from 'styled-components';

import { mainColors } from 'src/theme/defaultTheme';

const PageSpinnerWrapper = styled.div`
  height: 100%;
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullPageSpinnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageSpinner = () => (
  <PageSpinnerWrapper>
    <RotateSpinner size={60} color={mainColors.eggplant} />
  </PageSpinnerWrapper>
);
export const FullPageSpinner = () => (
  <FullPageSpinnerWrapper>
    <RotateSpinner size={60} color={mainColors.eggplant} />
  </FullPageSpinnerWrapper>
);

export const InlineSpinner:React.FC<{color?:string}> = ({color= mainColors.mountbatten}) => {
  return <RotateSpinner size={30} color={color} />;
};
