import React from 'react';

import useAuthStore from 'src/store/AuthStore';

interface IWithEditPrivilege {
  children?: any;
}

const WithEditPrivilege: React.FC<IWithEditPrivilege> = ({ children }) => {
  const { isAuthenticated, customRole } = useAuthStore((state) => state);

  if (isAuthenticated && customRole === 'editor') {
    return <>{children}</>;
  }

  return null;
};

export default WithEditPrivilege;
