import styled from 'styled-components';

export const SmallResolutionWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  padding:10%;
  background-color: ${({theme}) => theme.colors.darkBrown};
  &>p{
    color:${({theme})=>theme.colors.babypowder};
  }
  &>svg>g>path:not(*:last-child, *:nth-child(30),*:nth-child(31),*:nth-child(33)){
    fill:${({theme})=>theme.colors.babypowder};
  }
  &>svg{
    max-width:90%;
  }
`;
