/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import Flex from 'src/components/UI/elements/flex/Flex';

import {
  BackgroundImageProps,
  ImageElementProps,
  TextElementProps,
  StyledBlockElementProps,
} from 'src/types/interface';
import { PageBlockType } from 'src/types/layout.interface';

interface IPageText {
  highlighted:boolean;
  textAlign?: any;
  fontSize?: any;
  color?: any;
}
export const PageBlockContainer = styled.div<PageBlockType>`
  ${({
    top,
    left,
    width,
    height,
    zIndex,
    backgroundColor,
    showBorder = true,
    selected,
  }) => css`
    position: absolute;
    top: ${top}px;
    left: ${left}px;
    outline: ${showBorder ? (selected ? '' : '3px solid black') : ''};
    width: ${width}px;
    height: ${height}px;
    z-index: ${selected ? zIndex + 100 : zIndex};
    background-color: ${backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    ${!selected &&
    css`
      &:hover {
        transition: 0.2s;
        border-radius: 8px;
        cursor: pointer;
        outline: 3px solid ${({ theme }) => theme.colors.teal};
      }
    `}/* overflow: hidden; */
  `}
`;
export const ContentBlockContainer = styled(Flex)`
  flex-direction: column;
  outline: 3px solid white;
  color: white;
  mix-blend-mode: difference;
  margin: 5px;
  padding: 5px;
  & > div > button {
    border: 2px solid white;
    padding: 10px;
    margin: 10px;
    &:hover {
      background-color: white;
      color: black;
    }
  }
`;
export const TextInputStyled = styled.input`
  width: 100%;
  height: 20px;
  border: 0.1px solid aqua;
  color: black;
  mix-blend-mode: unset;
  &:focus {
    border: 1px solid aquamarine;
  }
`;
export const StyledLabel = styled.label`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledLabelText = styled.span`
  color: black;
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 120px;
  border: 0.1px solid aqua;

  &:focus {
    border: 1px solid aquamarine;
  }
`;
export const StyledSelect = styled.select`
  width: 100%;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.mountbatten};
  &:focus {
    border: 1px solid aquamarine;
  }
`;

export const StyledTextElement = styled.p<TextElementProps>`
  ${({ color, fontSize, textAlign, padding, float }) => css`
    white-space: pre;
    flex: 1;
    color: ${color};
    font-size: ${fontSize}px;
    text-align: ${textAlign};
    padding: ${padding}px;
    height: 100%;
    width: 100%;
    ${float &&
    float === 'center' &&
    css`
      -webkit-box-pack: center;
    `}
    ${float &&
    float === 'end' &&
    css`
      -webkit-box-pack: end;
    `}
  ${float &&
    float === 'start' &&
    css`
      -webkit-box-pack: start;
    `}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: -moz-hidden-unscrollable;
    text-overflow: ellipsis;
  `}
`;

export const StyledImageElement = styled.img<ImageElementProps>`
  width: 100%;
  height: 100%;
  ${({ left, objectFit, opacity, scale, top }) => css`
    object-fit: ${objectFit};
    opacity: ${opacity};
    transform: translateX(${left}%) translateY(${top}%) scale(${scale});
  `}
`;

export const StyledBackgroundImage = styled.img<BackgroundImageProps>`
  width: 100%;
  height: 100%;
  ${({ left, objectFit, opacity, scale, top, background }) => css`
    object-fit: ${objectFit};
    opacity: ${opacity};
    transform: translateX(${left}%) translateY(${top}%) scale(${scale});
    background-color: ${background};
  `}
`;
export const StyledBackgroundDiv = styled.div<BackgroundImageProps>`
  width: 100%;
  height: 100%;
  ${({ left, objectFit, opacity, scale, top, background }) => css`
    object-fit: ${objectFit};
    opacity: ${opacity};
    transform: translateX(${left}%) translateY(${top}%) scale(${scale});
    background-color: ${background};
  `}
`;

export const StyledBlockDiv = styled.div<StyledBlockElementProps>`
  width: 100%;
  height: 100%;
  ${({ left, top, opacity, border, background }) => css`
    border: ${border};
    opacity: ${opacity};
    transform: translateX(${left}%) translateY(${top}%));
    background-color: ${background};
  `}
`;
export const EditorPageText = styled.p<IPageText>`
  min-height: 35px;
  white-space: pre-line;
  outline: 3px solid transparent;
  transition: 0.2s;
`;
export const EditorPageTextContainer = styled.div<IPageText>`
  min-height: 35px;
  white-space: pre-wrap;
  outline: 3px solid transparent;
  transition: 0.2s;
  & * {
    ${({ fontSize }) =>
      !!fontSize &&
      css`
        font-size: ${fontSize}px;
      `}
    ${({ color }) =>
      !!color &&
      css`
        color: ${color};
      `}
  }
  & .centeralign > *,
  & .centeralign span {
    display: flex;
    justify-content: center;
    align-items:baseline;
    flex-wrap: wrap;
    text-align: center;
  }
  & .leftalign > *,
  & .leftalign span {
    display: flex;
    justify-content: flex-start;
    align-items:baseline;
    flex-wrap: wrap;
    text-align: left;
  }
  & .rightalign > *,
  & .rightalign span {
    display: flex;
    justify-content: flex-end;
    align-items:baseline;
    flex-wrap: wrap;
    text-align: right;
  }
`;
export const InlineStyleContainer = styled.div`
  margin: 0 16px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    font-size: 26px;
  }
`;

export const TextEditorMenu = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  height: 54px;
  background: white;
  border-radius: 8px;
  z-index: 10000009;
  display: flex;
  align-items: center;
  padding: 5px;
  outline: 3px dashed teal;
  &>*{
    margin-right:8px;
  }
  .BOLD {
    font-weight: 800;
  }
  .ITALIC {
    font-style: italic;
  }
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .RichEditor-controls-container {
    border-bottom: 1px solid #ccc;
    background: #fff;
    z-index: 10;
  }

  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    background-color: green;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
`;

export const RichTextContainer = styled.div<{
  height: number | string;
  textAlign: string;
  overflown: boolean;
}>`
  z-index: auto;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  transition: 0.2s;
  outline: 3px solid
    ${({ overflown, theme }) => (overflown ? 'red' : theme && theme.colors.teal)};
    & .centeralign > *,
  & .centeralign span {
    display: flex;
    justify-content: center;
    align-items:baseline;
    flex-wrap: wrap;
    text-align: center;
  }
  & .leftalign > *,
  & .leftalign span {
    display: flex;
    justify-content: flex-start;
    align-items:baseline;
    flex-wrap: wrap;
    text-align: left;
  }
  & .rightalign > *,
  & .rightalign span {
    display: flex;
    justify-content: flex-end;
    align-items:baseline;
    flex-wrap: wrap;
    text-align: right;
  }
`;
