import { gql } from 'graphql-request';

export const GetChapters = gql`
  query GetChapters {
    chapters(where: { isLocked: true }, sort: "order:asc") {
      id
      title
      chapterSplash {
        url
      }
      description
      order
      isLocked
      introMessage
      group
      pages {
        id
        name
        writingTips
        layout {
          id
          name
          pageType
          blocks {
            id
            x
            y
            width
            height
            layer
            backgroundColor
            ident
            content {
              id
              type
              values
            }
          }
        }
        value
        background
      }
    }
  }
`;
export const GetOneChapter = gql`
  query GetOneChapter($id: ID!) {
    chapter(id: $id) {
      id
      title
      chapterSplash {
        url
      }
      description
      order
      isLocked
      introMessage
      group
      pages {
        id
        name
        writingTips
        layout {
          id
          name
          pageType
          blocks {
            id
            x
            y
            width
            height
            layer
            backgroundColor
            ident
            content {
              id
              type
              values
            }
          }
        }
        value
        background
      }
    }
  }
`;
export const GetAllChapters = gql`
  query GetAllChapters {
    chapters(sort: "order:asc") {
      id
      title
      chapterSplash {
        url
      }
      description
      order
      isLocked
      introMessage
      group
      pages(sort: "order:asc") {
        order
        id
        name
        writingTips
        layout {
          id
          name
          pageType
          blocks {
            id
            x
            y
            width
            height
            layer
            backgroundColor
            ident
            content {
              id
              type
              values
            }
          }
        }
        value
        background
      }
    }
  }
`;
