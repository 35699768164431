import { useMutation, useQuery } from 'react-query';

import GraphQLConnector from '../GraphQLClient';
import { CreateAdminImage } from '../mutations/image-mutations/CreateAdminPhoto.gql';
import { DeleteImageMutation } from '../mutations/image-mutations/ImageMutations';
import {
  GetAdminImages,
  GetStockPhotos,
  GetUserGallery,
} from '../queries/ImageQueries.gql';
import { GetProfilePicture } from '../queries/UserQueries.gql';

export const useGetStockPhotos = () =>
  useQuery(
    'get-all-stock-photos',
    async () => {
      const response = await GraphQLConnector.request(GetStockPhotos);
      return response;
    },
    { refetchOnWindowFocus: false }
  );

export const useGetUserGallery = (id: string) =>
  useQuery(
    'get-user-gallery-photos',
    async () => {
      const response = await GraphQLConnector.request(GetUserGallery, { userID: id });
      return response;
    },
    { refetchOnWindowFocus: false }
  );

export const useDeletePhoto = () =>
  useMutation('delete-image-mutation', async (x: { id: string }) => {
    const response = await GraphQLConnector.request(DeleteImageMutation, {
      input: {
        where: {
          id: x.id,
        },
      },
    });
    return response;
  });

export const useGetAdminImages = () =>
  useQuery(
    'get-all-admin-images',
    async () => {
      const response = await GraphQLConnector.request(GetAdminImages);
      return response;
    },
    { refetchOnWindowFocus: false }
  );

export const useCreateAdminImage = () =>
  useMutation('create-page', async (id: string) => {
    const response = await GraphQLConnector.request(CreateAdminImage, {
      input: {
        data: { file: id },
      },
    });
    return response;
  });

export const useGetProfilePicture = (
  userID: string,
  token: string,
  isAuthenticated: boolean
) =>
  useQuery(
    'get-profile-picture',
    async () => {
      GraphQLConnector.setHeader('authorization', `Bearer ${token}`);
      const { user } = await GraphQLConnector.request(GetProfilePicture, {
        userID: parseInt(userID, 10),
      });
      return user;
    },
    { enabled: isAuthenticated, refetchOnWindowFocus: false }
  );
