import { useEffect, useState } from 'react';
import useDebounce from 'src/utils/useDebounce';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const debounce = useDebounce(1000)
  useEffect(() => {
    const handleResize=debounce(()=> {
      setWindowDimensions(getWindowDimensions());
    })
    let listener;
    if (!listener) {
      listener = window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [debounce]);
  return windowDimensions;
};

export default useWindowDimensions;
