import { Link } from 'react-router-dom';
import { TOOL_BAR_HEIGHT } from 'src/app/config/constants';
import styled, { css } from 'styled-components';

export const MenuBarWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - ${TOOL_BAR_HEIGHT}px);
  align-items: center;
  width: 84px;
  padding-top: 72px;
  list-style: none;
  background-color: ${({ theme }) => theme.colors.isabelline50};
  box-sizing: border-box;
`;

export const OverviewMenuBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const MenuItem = styled(Link)<{ active: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  text-decoration: none;
  ${({ active, theme }) =>
    active === 'true' &&
    css`
      background-color: ${theme.colors.eggplant};
      width: 100%;
      padding: 5px 0;
      & span {
        color: ${theme.colors.white};
      }
    `};

  &:hover {
    svg {
      rect,
      path[stroke='#A0889A'] {
        stroke: #51404c;
      }
      circle,
      path[fill='#A0889A'] {
        fill: #51404c;
      }
    }
  }
`;

export const MenuItemTitle = styled.span`
  color: black;
  margin-top: 10px;
`;
