import { Link, NavLink } from 'react-router-dom';
import { TOOL_BAR_HEIGHT } from 'src/app/config/constants';
import { BaseButtonWithBorder } from 'src/components/UI/elements/buttons';
import styled from 'styled-components';

export const NavLoginButton = styled(BaseButtonWithBorder)`
  width: 90px;
`;

export const NavigationBar = styled.nav`
  height: ${TOOL_BAR_HEIGHT}px;
  width: 100vw;
  background-color: ${({theme})=>theme.colors.white};
  &:after{
    position:absolute;
    pointer-events:none;
    box-shadow: -4px 4px 10px rgba(173, 173, 173,0.25);
    left:0;
    width:100vw;
    margin-top: -${TOOL_BAR_HEIGHT}px;
    height: ${TOOL_BAR_HEIGHT}px;
    background-color: transparent;
    content:'';
  }
`;
interface INavContainer {
  wide?: boolean;
}
export const NavContainer = styled.div<INavContainer>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${({ wide }) => (wide ? '100%' : '1440px')};
  margin: 0 auto;
  padding: 0 2em;
`;

export const Menu = styled.div`
  display: flex;
  max-width: 650px;
  justify-content: space-between;
  align-items: center;
`;
export const NavigationList = styled.ul`
  justify-self: flex-start;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
`;

export const NavigationListItem = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  margin: 0 10px;
  color: ${({ theme }) => theme.colors.mountbatten};
  transition: color ease-in 100ms;
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.eggplant};
  }
`;

export const NavigationProfile = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
`;

export const NavigationProfileName = styled.span`
  color: ${({ theme }) => theme.colors.mountbatten};
  font-size: 16px;
  font-weight: 600;
`;

export const NavigationProfileIcon = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.isabelline};
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  svg {
    height: 30px;
    width: 20px;
    color: ${({ theme }) => theme.colors.eggplant};
  }
`;

export const NavigationSignupLink = styled(Link)`
  color: ${({ theme }) => theme.colors.eggplant};
  font-weight: bold;
`;

export const ProfilePictureWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 1em;
  img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;
