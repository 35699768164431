import { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

import Flex from 'src/components/UI/elements/flex/Flex';

export const LayoutWorkingPad = styled.div<{
  scale: number;
  pageType?: string | undefined;
}>`
  position: relative;
  margin-left: 20px;
  margin-right: 50px;
  overflow: hidden;
  border: black 1px dashed;
  ${({ scale, theme, pageType }) => css`
    transform: scale(${scale || 1});
    background-color: ${theme.colors.white};
    box-shadow: 9px 9px 9px rgba(235, 227, 222, 0.5);
    min-width: ${pageType === 'A3' ? theme.pageType.a3.width : theme.pageType.a4.width}px;
    max-width: ${pageType === 'A3' ? theme.pageType.a3.width : theme.pageType.a4.width}px;
    min-height: ${pageType === 'A3'
      ? theme.pageType.a3.height
      : theme.pageType.a4.height}px;
    max-height: ${pageType === 'A3'
      ? theme.pageType.a3.height
      : theme.pageType.a4.height}px;
  `};
`;

export const LayoutControlPanel = styled.div`
  width: 400px;
  height: 100vh;
  overflow-y: auto;
  border: 1px solid grey;
`;
export const LayoutCreatorContainer = styled.div`
  /* width: 1000px; */
  height: 1200px;
  display: flex;
  flex-direction: row;
`;
export const ControlPanelBlock = styled(Flex)<{
  bgColor: string;
  onClick: (e: SyntheticEvent) => void;
}>`
  ${({ bgColor }) =>
    bgColor
      ? css`
          background-color: ${bgColor};
          color: white;
        `
      : css`
          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.eggplant};
            transition: all 0.1s;
          }
        `}
  display: flex;

  flex-direction: column;
  padding: 20px;
  margin: 10px;

  transition: all 1s;
`;
export const ScaleFlexContainer = styled.div<{ pageType?: string | undefined }>`
  display: flex;
  min-height: 220px;
  max-height: 220px;
  margin: 25px;
  position: relative;
  cursor: pointer;
  ${({ pageType }) => css`
    min-width: ${pageType === 'A3' ? 320 : 160}px;
    max-width: ${pageType === 'A3' ? 320 : 160}px;
  `}
  &:hover {
    filter: brightness(30%);
    background-color: white;
  }
`;

export const CollapseButton = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  top: 3px;
  color: gray;
  background: white;
  font-size: 25px;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: gray;
    color: white;
  }
`;
