import React from 'react';

export default function PlayButtonIcon() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='15.9998' cy='15.9998' r='13.3333' fill='#51404C' />
      <path
        d='M22.6665 16.0002L12.6665 21.7737L12.6665 10.2267L22.6665 16.0002Z'
        fill='white'
      />
    </svg>
  );
}
