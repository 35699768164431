/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function AddIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12.5' cy='12' r='10' fill='white' />
      <path
        d='M12.501 22C6.98011 21.9939 2.50606 17.5203 2.5 12V11.8C2.60994 6.30455 7.13505 1.92797 12.6317 2.0009C18.1283 2.07382 22.5357 6.5689 22.4998 12.0653C22.4639 17.5618 17.9981 21.9989 12.501 22ZM7.5005 11V13H11.5009V17H13.5011V13H17.5015V11H13.5011V7.00001H11.5009V11H7.5005Z'
        fill='#51404C'
      />
    </svg>
  );
}
