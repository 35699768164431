import React from 'react';

import { PageBlockType } from 'src/types/layout.interface';

import { PageBlockContainer } from './styled';

const PageBlock: React.FC<PageBlockType> = ({ children, ...props }) => {
  return <PageBlockContainer {...props}>{children}</PageBlockContainer>;
};

export default PageBlock;
