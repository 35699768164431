import styled, { css } from 'styled-components';

import Flex from 'src/components/UI/elements/flex/Flex';

import Typography from './typography/Typography';

import { mainColors } from 'src/theme/defaultTheme';

export const RelativeContainer = styled.div`
  height: 100%;
  padding-bottom: 10vh;
  position: relative;
`;
export const UploadWrap = styled.div`
  overflow-y: scroll;
  height: 65vh;
  padding-bottom: 10vh;
`;
export const FileUploadContainer = styled.section`
  position: relative;
  margin: 60px 0 15px;
  border: 1px dashed ${mainColors.mountbatten};
  padding: 15px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  &:focus {
    outline: none;
  }
`;

export const InputLabel = styled.label`
  top: -55px;
  left: 0;
  position: absolute;
`;

export const UploadFileBtn = styled.button`
  width: 120px;
  height: 40px;
  background-color: ${mainColors.mountbatten};
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin: 15px 0;
  border-color: transparent;
  transition: background-color ease-in 100ms;
  &:hover,
  &:focus {
    background-color: ${mainColors.eggplant};
  }
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;
  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const FileMetaData = styled.div<{ isImageFile: boolean }>`
  display: ${(props) => (props.isImageFile ? 'none' : 'flex')};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);
  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const DragAndDropText = styled.p`
  font-size: 14px;
  color: ${mainColors.eggplant};
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
`;

export const PreviewContainer = styled.div<{ isEditing?: boolean }>`
  ${({ theme, isEditing }) => css`
    padding: 0.25rem;
    width: 100%;
    height: 120px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    &::after {
      transition: all ease-in 200ms;
      content: '✓';
      font-size: ${isEditing ? '20px' : 0};
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.white};
      background-color: ${isEditing ? theme.colors.mountbatten : 'transparent'};
      opacity: 0.8;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &:hover {
      background-color: ${theme.colors.isabelline50};
      &::after {
        background-color: #a0889a;
        opacity: 0.8;
      }
    }
  `}
`;

export const ImagePreview = styled.div<{ imageURL: string }>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
export const GalleryGrid = styled.div`
  display: grid;
  margin: 2rem 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 120px 120px;
  gap: 10px;
  overflow-x: scroll;
`;
export const ShowAllImg = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 15px 0;
`;
export const ShowImgText = styled(Typography)`
  transition: color ease-in 100ms;
  &:hover,
  &:focus {
    color: ${mainColors.eggplant};
  }
`;
export const ImageRow = styled(Flex)`
  flex-wrap:wrap;
  padding:0 4px;
`

export const ImageColumn=styled.div`
flex:25%;
max-width:25%;
padding:0 4px;
&>img{
  margin-top: 8px;
  vertical-align:middle;
  width:100%;
}
`
