import styled, { css } from 'styled-components';

import { InteractiveStyled, TypographyProps } from './typography.interface';

const pxOrString = (value: string | number) =>
  typeof value === 'number' ? `${value}px` : value;


export const TextStyled = styled.p<TypographyProps & InteractiveStyled>`
  ${({
    bold,
    medium,
    size,
    fontFamily,
    fontWeight,
    align,
    color,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    lineHeight,
    letterSpacing,
    uppercase,
    theme,
    nowrap,
    maxLineCount,
    italic,
    focused,
    current,
  }) => {
    return css`
      font-size: ${typeof size === 'string'
        ? theme.fontSizes[size || 'md']
        : `${size || 16}px`};
      ${medium &&
      css`
        font-weight: ${theme.fontWeight.medium};
      `}
      ${bold &&
      css`
        font-weight: ${theme.fontWeight.bold};
      `}
      ${italic &&
      css`
        font-style: italic;
      `}  ${fontWeight &&
      css`
        font-weight: ${theme.fontWeight[fontWeight]};
      `}
      ${fontFamily &&
      css`
        font-family: ${theme.fontFamily[fontFamily]};
      `}
      text-align:${align || 'left'};
      color: ${theme.colors[color || 'blackText']};
      ${marginTop &&
      css`
        margin-top: ${marginTop}px;
      `}
      ${marginBottom &&
      css`
        margin-bottom: ${marginBottom}px;
      `}
      ${marginRight &&
      css`
        margin-right: ${marginRight}px;
      `}
      ${marginLeft &&
      css`
        margin-left: ${marginLeft}px;
      `}
      ${lineHeight &&
      css`
        line-height: ${lineHeight}px;
      `}
      ${letterSpacing &&
      css`
        letter-spacing: ${pxOrString(letterSpacing)};
      `}
      ${uppercase &&
      css`
        text-transform: uppercase;
      `}
      ${nowrap &&
      css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
      ${maxLineCount &&
      css`
        display: -webkit-box;
        -webkit-line-clamp: ${maxLineCount};
        -webkit-box-orient: vertical;
        overflow: hidden;
      `}
      ${current &&
      !focused &&
      css`
        &:hover {
          cursor: pointer;
        }
      `}
      ${focused &&
      current &&
      css`
        background: rgba(0, 0, 0, 0.5);
        color: white;
      `}
    `;
  }}
`;

// export const TextStyledInput = styled.textarea<
//   TypographyProps & Omit<InteractiveStyled, 'current'>
// >`
//   ${({
//     bold,
//     medium,
//     size,
//     fontFamily,
//     fontWeight,
//     align,
//     color,
//     marginTop,
//     marginBottom,
//     marginLeft,
//     marginRight,
//     lineHeight,
//     letterSpacing,
//     uppercase,
//     theme,
//     nowrap,
//     maxLineCount,
//     italic,
//   }) => css`
//     margin: 2px;
//     ${medium &&
//     css`
//       font-weight: ${theme.fontWeight.medium};
//     `}
//     ${bold &&
//     css`
//       font-weight: ${theme.fontWeight.bold};
//     `}
//       ${italic &&
//     css`
//       font-style: italic;
//     `}  ${fontWeight &&
//     css`
//       font-weight: ${theme.fontWeight[fontWeight]};
//     `}
//       ${fontFamily &&
//     css`
//       font-family: ${theme.fontFamily[fontFamily]};
//     `}
//       text-align:${align || 'left'};
//     color: ${theme.colors[color || 'blackText']};
//     ${marginTop &&
//     css`
//       margin-top: ${marginTop + 2}px;
//     `}
//     ${marginBottom &&
//     css`
//       margin-bottom: ${marginBottom + 2}px;
//     `}
//       ${marginRight &&
//     css`
//       margin-right: ${marginRight + 2}px;
//     `}
//       ${marginLeft &&
//     css`
//       margin-left: ${marginLeft + 2}px;
//     `}
//       ${lineHeight &&
//     css`
//       line-height: ${lineHeight + 2}px;
//     `}
//       ${letterSpacing &&
//     css`
//       letter-spacing: ${pxOrString(letterSpacing)};
//     `}
//       ${uppercase &&
//     css`
//       text-transform: uppercase;
//     `}
//       ${nowrap &&
//     css`
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     `}
//       ${maxLineCount &&
//     css`
//       display: -webkit-box;
//       -webkit-line-clamp: ${maxLineCount};
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//     `}
//     border: 0.45px solid ${theme.colors.vividBlue};
//     background-color: rgba(255, 255, 255, 0.2);
//     color: ${theme.colors.blackText};
//     outline: none;
//     min-height: ${lineHeight || 16}px;
//     max-height: 100%;
//     min-width: 100%;
//     overflow: auto;
//     resize: none;
//     transition: height 1s;
//     -webkit-transition: height 1s;
//     font-size: ${typeof size === 'string'
//       ? theme.fontSizes[size || 'md']
//       : `${size || 16}px`};
//     ::-webkit-scrollbar {
//       width: 4px;
//       height: 10px;
//     }

//     ::-webkit-scrollbar-track {
//       opacity: 0.3;
//       border-radius: 15px;
//     }

//     ::-webkit-scrollbar-thumb {
//       background-color: ${theme.colors.vividBlue};
//       border-radius: 15px;
//       opacity: 0.5;
//     }

//     ::-webkit-scrollbar-thumb:focus {
//       cursor: pointer;
//       background-color: ${theme.colors.vividBlue};
//     }
//   `}
// `;
