import React, { useEffect, useRef, useState } from 'react';

import { ScalingContainerProps } from './scalingContainer.interface';

import useWindowDimensions from 'src/services/hooks/useWindowDimention';

import { ContentAbsolute, ExternalContainer, StyledContainer } from './styled';
// Parent should have display flex
// Flexible container based on window inner width/height
// defaults to track HEIGHT and WIDTH.
// DEFINE size - % of the window inner width
// float - defaults to LEFT  (LEFT/RIGHT/CENTER)

// Cons: atm top padding is not implemented - use wrapping techniques to achieve this

const ScalingContainer: React.FC<ScalingContainerProps> = ({
  children,
  size,
  widthGap,
  heightGap,
  defaultWidth = 1190,
  defaultHeight = 842,
  fromParent = true,
  flexType,
  float = 'CENTER',
}) => {
  const { width, height } = useWindowDimensions();
  const element = useRef<HTMLDivElement>(null);
  const [contentWidth, contentHeight] = [defaultWidth, defaultHeight];
  const [parWidth, setParWidth] = useState<number>(0);
  const [parHeight, setParHeight] = useState<number>(0);

  useEffect(() => {
    if (element && fromParent) {
      setParWidth(element?.current?.offsetWidth || 1);
      setParHeight(element?.current?.offsetHeight || 1);
    }
  }, [element, fromParent, width]);
  // Calculating final width taking Gap into the account
  const widthProp =
    flexType !== 'HEIGHT' &&
    ((((parWidth || width) - (widthGap || 0)) / contentWidth) * size) / 100;

  const heightProp =
    flexType !== 'WIDTH' &&
    ((((parHeight || height) - (heightGap || 0)) / contentHeight) * size) / 100;

  // Defining scale propery - can be parent oriented and window oriented
  const scale = flexType
    ? widthProp || heightProp
    : Math.min(widthProp || 0, heightProp || 0);

  // Calculating ceter position as left-offset
  const centerPosition = parWidth / 2 - (defaultWidth * (scale || 1)) / 2;

  return (
    <ExternalContainer ref={element}>
      <ContentAbsolute float={float} scaledWidth={centerPosition}>
        <StyledContainer scale={Number(scale)} float={float}>
          {children}
        </StyledContainer>
      </ContentAbsolute>
    </ExternalContainer>
  );
};

export default ScalingContainer;
