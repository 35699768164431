import styled from 'styled-components';

import MainTheme from 'src/theme/defaultTheme';

interface ITypographyProps {
  theme: typeof MainTheme;
  centered?: boolean;
  bold?: boolean;
  fontSize?: string;
}

export const Header1 = styled.h1`
  margin-bottom: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.textColors.h1};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const Header2 = styled.h2`
  margin-bottom: 12px;
  font-weight: 700;
  font-family: ${({theme})=>theme.fontFamily.openSans};
  color: ${({ theme }) => theme.textColors.h2};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const Header3 = styled.h3`
  margin-bottom: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.textColors.h3};
  font-size: ${({ theme }) => theme.fontSizes.heading3};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const Header4 = styled.h4`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.textColors.h4};
  font-size: ${({ theme }) => theme.fontSizes.heading4};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const Header5 = styled.h5`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.textColors.h5};
  font-size: ${({ theme }) => theme.fontSizes.heading5};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const Header6 = styled.h6`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.textColors.h6};
  font-size: ${({ theme }) => theme.fontSizes.heading6};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const Paragraph = styled.p`
  font-size: ${(props: ITypographyProps) => (props.fontSize ? props.fontSize : '16px')};
  color: ${({ theme }) => theme.textColors.p};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  line-height: 24px;
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
export const ParagraphContainer = styled.div`
  font-size: ${(props: ITypographyProps) => (props.fontSize ? props.fontSize : '16px')};
  color: ${({ theme }) => theme.textColors.p};
  text-align: ${(props: ITypographyProps) => (props.centered ? 'center' : 'initial')};
  line-height: 24px;
  font-weight: ${(props: ITypographyProps) => (props.bold ? 'bold' : 'initial')};
`;
