import React from 'react';
import { MINIMUM_SUPPORTED_RESOLUTION } from 'src/app/config/constants';
import SmallResultionWarning from 'src/pages/public/SmallResolutionWarning/SmallResultionWarning';
import useMediaQuery from 'src/services/hooks/useMediaQuery';

const MediaQueryWrapper: React.FC = ({ children }) => {
  const isSupported = useMediaQuery(`(min-width: ${MINIMUM_SUPPORTED_RESOLUTION}px)`)
  if (!isSupported) {
    return <SmallResultionWarning />;
  }
  return <>{children}</>;
};
export default MediaQueryWrapper;
