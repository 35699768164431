/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function ExportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#A0889A"
        d="M2 15a1 1 0 10-2 0h2zm22 0a1 1 0 10-2 0h2zm-4 7H4v2h16v-2zM2 20v-5H0v5h2zm20-5v5h2v-5h-2zM4 22a2 2 0 01-2-2H0a4 4 0 004 4v-2zm16 2a4 4 0 004-4h-2a2 2 0 01-2 2v2z"
       />
      <path
        stroke="#A0889A"
        strokeLinecap="round"
        strokeWidth="2"
        d="M12 6v11"
       />
      <path
        fill="#A0889A"
        d="M8.6 14.2a1 1 0 10-1.2 1.6l1.2-1.6zM12 18l-.6.8a1 1 0 001.2 0L12 18zm4.6-2.2a1 1 0 00-1.2-1.6l1.2 1.6zm-9.2 0l4 3 1.2-1.6-4-3-1.2 1.6zm5.2 3l4-3-1.2-1.6-4 3 1.2 1.6z"
       />
    </svg>
  );
}
