import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import NotFound from 'src/pages/general/404';
import { FullPageSpinner } from 'src/components/UI/spinners.styled';

import useAuthStore from 'src/store/AuthStore';
import MediaQueryWrapper from 'src/components/wrappers/MediaQueryWrapper';
import { ROUTES } from 'src/app/config/constants';

type RouteType = {
  path: string;
  exact?: boolean;
  private?: boolean;
  component: any;
};

type Props = {
  routes: RouteType[];
};

const RenderRoutes: React.FC<Props> = ({ routes }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const renderRoute = ({
    path,
    exact = true,
    private: isPrivate,
    component: Component,
  }: RouteType) => {
    return (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={(props) => {
          if (isPrivate && !isAuthenticated) {
            return <Redirect to={ROUTES.login} />;
          }
          if (path.includes(ROUTES.login) && isAuthenticated) {
            return <Redirect to={ROUTES.overview} />;
          }
          return (
            <MediaQueryWrapper>
              <Component {...props} />
            </MediaQueryWrapper>
          );
        }}
      />
    );
  };

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Switch>
        {routes.map(renderRoute)}
        <Route render={() => <NotFound />} />
      </Switch>
    </Suspense>
  );
};

export default RenderRoutes;
