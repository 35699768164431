import { AddIcon } from './AddIcon';
import { CardIcon } from './CardIcon';
import { ChaptersIcon } from './ChaptersIcon';
import { CollapsIcon } from './CollapsIcon';
import { DocumentIcon } from './DocumentIcon';
import { EditIcon } from './EditIcon';
import { EditIconWithEllipse } from './EditIconWithEllipse';
import { ExportIcon } from './ExportIcon';
import { GridViewIcon } from './GridViewIcon';
import { ImagesIcon } from './ImagesIcon';
import { InfoIcon } from './InfoIcon';
import { ListViewIcon } from './ListViewIcon';
import { LogoutIcon } from './LogoutIcon';
import { NextIcon } from './NextIcon';
import { NextIconEllipse } from './NextIconEllipse';
import { PagesIcon } from './PagesIcon';
import { PaletteIcon } from './PaletteIcon';
import PlayButtonIcon from './PlayButtonIcon';
import { PrevIcon } from './PrevIcon';
import { PrevIconEllipse } from './PrevIconEllipse';
import SpreadIcon from './SpreadIcon';
import { TextIcon } from './TextIcon';

const icons = {
  MENU_ICON_CHAPTERS: ChaptersIcon,
  MENU_ICON_IMAGES: ImagesIcon,
  MENU_ICON_TEXT: TextIcon,
  MENU_ICON_PAGES: PagesIcon,
  MENU_ICON_EXPORT: ExportIcon,
  MENU_GRID_VIEW_ICON: GridViewIcon,
  MENU_LIST_VIEW_ICON: ListViewIcon,
  COLLAPSE_ICON: CollapsIcon,
  PLAY_ICON: PlayButtonIcon,
  NEXT_ICON: NextIcon,
  PREV_ICON: PrevIcon,
  EDIT_ICON: EditIcon,
  CARD_ICON: CardIcon,
  LOGOUT_ICON: LogoutIcon,
  EDIT_ICON_WITH_ELLIPSE: EditIconWithEllipse,
  PALETTE_ICON: PaletteIcon,
  ADD_ICON: AddIcon,
  NEXT_ICON_ELLIPSE: NextIconEllipse,
  PREV_ICON_ELLIPSE: PrevIconEllipse,
  INFO_ICON: InfoIcon,
  DOCUMENT_ICON: DocumentIcon,
  SPREAD_ICON: SpreadIcon,
};

export default icons;
