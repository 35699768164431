import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import icons from 'src/theme/icons';

interface IButtonProps {
  cancel?: boolean;
  disabled?: boolean;
}

interface IImageProps {
  isImageSuccess: boolean;
}

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#FF8080';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#A0889A';
};

export const ModalContainer = styled.div`
  max-width: 500px;
  padding: 0 2em;
`;
export const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.colors.grayishPink};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-weight: bold;
  font-size: 28px;
`;
export const ModalText = styled.p`
  color: ${({ theme }) => theme.colors.grayishPink};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  margin: 2em 0;
  line-height: 160%;
`;
export const DeleteLink = styled.p`
  color: ${({ theme }) => theme.colors.grayishPink};
  cursor: pointer;
  font-size: 14px;
  :hover {
    text-decoration: underline;
  }
`;
export const ProgressBar = styled.progress``;
export const LoadingText = styled.p`
  color: ${({ theme }) => theme.colors.mountbatten};
  margin-bottom: 2em;
`;

export const BaseButton = styled.button<IButtonProps>`
  background: ${({ theme, cancel }) =>
    cancel ? theme.colors.isabelline50 : theme.colors.mountbatten};
  color: ${({ theme, cancel }) =>
    cancel ? theme.colors.mountbatten : theme.colors.white};
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  padding: 6px 15px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: ${({ cancel }) => cancel && '1em'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  :hover {
    background: ${({ theme, cancel }) =>
      cancel ? theme.colors.mountbatten : theme.colors.grayishPink};
    color: ${({ theme, cancel }) =>
      cancel ? theme.colors.isabelline50 : theme.colors.white};
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  min-height: 360px;
  max-height: 360px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  background: ${({ theme }) => theme.colors.grayishPink};
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  right: 65px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.white};
    svg path {
      fill: ${({ theme }) => theme.colors.grayishPink};
    }
  }
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const EditIcon = () => {
  return (
    <IconWrapper>
      <icons.EDIT_ICON />
    </IconWrapper>
  );
};

export const BaseLink = styled(Link)`
  font-size: 12px;
  font-weight: bold !important;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayishPink};
  padding-right: 1em;
  :hover {
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div<IImageProps>`
  background: ${({ theme }) => theme.colors.isabelline50};
  margin: 3em auto 0;
  width: 80em;

  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 30%;
    background: white;
    height: 600px;
    max-width: 320px;
    box-shadow: 5px 5px 5px rgba(235, 227, 222, 0.5);
    border-radius: 8px;
  }
  .react-tabs {
    display: flex;
    flex-direction: row;
  }
  .react-tabs__tab {
    padding: 6px 12px 0;
    div {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayishPink};
    }
  }
  .image-tab {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 2em;
    position: relative;
    img {
      width: auto;
      height: inherit;
      object-fit: cover;
    }
  }
  .image-tab-selected {
    position: relative;
    > div {
      img {
        border: 1px dashed ${({ theme }) => theme.colors.mountbatten};
        opacity: ${({ isImageSuccess }) => (isImageSuccess ? 1 : 0.2)};
      }
    }
  }
  .react-tabs__tab--selected {
    background: ${({ theme }) => theme.colors.sand};
    color: ${({ theme }) => theme.colors.grayishPink};
    font-weight: bold;
    border-color: transparent;
    border-radius: 0;
    /* margin:2px 0; */
    div {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayishPink};
      p {
        font-weight: bold;
        /* color: ${({ theme }) => theme.colors.white}; */
      }
      svg > path {
        /* fill: ${({ theme }) => theme.colors.white}; */
      }
    }
  }

  .react-tabs__tab-panel--selected {
    width: 100%;
  }
  .react-tabs__tab-list {
    border: none;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative;
  color: ${({ theme }) => theme.colors.grayishPink};
  width: 45%;
  & svg {
    position: absolute;
    right: 20px;
    top: 47px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const UploadWrap = styled.div`
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2em;
  progress[value] {
    width: 300px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    ::-webkit-progress-bar {
      height: 10px;
      background-color: #eee;
      border-radius: 20px;
    }
    ::-webkit-progress-value {
      height: 10px;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.colors.mountbatten};
    }
    ::-moz-progress-bar {
      height: 10px;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.colors.mountbatten};
    }
  }
`;

export const ThumbContainer = styled.div`
  display: flex;
  margin-top: 1em;
`;

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 50%;
  margin: 0 8px 8px 0;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

export const ThumbInner = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
`;

export const ThumbImg = styled.img`
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const InputLabelText = styled.label`
  margin-bottom: 8px;
  user-select: none;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayishPink};
`;

export const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 20px;
    top: 35px;
  }
`;

export const ImageWrap = styled.div`
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;

  img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

interface IInputProps {
  disabled?: boolean;
}

const InputField = styled.input<IInputProps>`
  background-color: ${({ theme }) => theme.colors.isabelline50};
  border-radius: 8px;
  border: none;
  padding: 15px;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:active,&:focus{
    background-color: ${({ theme }) => theme.colors.sand};
  }
`;

const StyledSelect = styled.select`
  background-color: ${({ theme }) => theme.colors.isabelline50};
  border-radius: 8px;
  border: none;
  padding: 15px;
  position: relative;
`;

export const FirstNameInput = styled(InputField)``;
export const LastNameInput = styled(InputField)``;
export const DateOfBirthInput = styled(InputField)``;
export const GenderInput = styled(StyledSelect)``;
export const EmailInput = styled(InputField)``;
export const PhoneInput = styled(InputField)``;
export const AddressInput = styled(InputField)``;
export const ZipInput = styled(InputField)``;
export const CityInput = styled(InputField)``;
export const CountryInput = styled(StyledSelect)``;
export const PasswordInput = styled(InputField)``;
export const NewPasswordInput = styled(InputField)``;
export const FunctionTitleInput = styled(InputField)``;
export const SubmitButton = styled.input<IButtonProps>`
  max-width: 200px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.mountbatten};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: none;
  padding: 15px;
  :hover {
    background: ${({ theme }) => theme.colors.grayishPink};
  }
`;
export const ErrorMessage = styled.p`
  font-size: 12px;
  margin: 1em 1em 0 0;
  color: ${({ theme }) => theme.colors.grayishPink};
`;
