import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';

import RenderRoutes from 'src/components/navigation/RenderRoutes/RenderRoutes';

import { ROUTES } from './config/constants';
import routes from './config/routes';

import defaultTheme from 'src/theme/defaultTheme';
import GlobalStyles from 'src/theme/globalStyles';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider
        theme={{
          ...defaultTheme,
        }}
      >
        <BrowserRouter>
          <Toaster />
          <GlobalStyles />
          <RenderRoutes routes={routes} />
          <Route path='/' exact>
            <Redirect to={ROUTES.login} />
          </Route>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
