import React from 'react';

import { FlexProps } from './flex.interface';

import { FlexStyled } from './styled';

const Flex: React.FC<FlexProps> = ({ children,...props }) => {
  return <FlexStyled {...props}>{children}</FlexStyled>;
};

export default Flex;
