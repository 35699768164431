import { useMutation, useQuery } from 'react-query';
import { CreateChapter } from '../mutations/chapter-mutations/CreateChapter.gql';
import { DeleteChapter } from '../mutations/chapter-mutations/DeleteChapter.gql';
import { UpdateChapter } from '../mutations/chapter-mutations/UpdateChapter.gql';
import {
  GetAllChapters,
  GetChapters,
  GetOneChapter,
} from '../queries/ChapterQueries.gql';

import GraphQLConnector from 'src/graphql/GraphQLClient';

export const useGetDefaultChapters = () =>
  useQuery('get-all-default-chapters', async () => {
    const response = await GraphQLConnector.request(GetChapters);
    return response;
  });

export const useGetChapters = () =>
  useQuery('get-all-chapters', async () => {
    const response = await GraphQLConnector.request(GetAllChapters);
    return response;
  });

export const useCreateChapter = (chapter: any) =>
  useMutation('create-chapter', async () => {
    const response = await GraphQLConnector.request(CreateChapter, {
      input: {
        data: chapter,
      },
    });
    return response;
  });

export const useUpdateChapterContent = () =>
  useMutation('update-chapter-content', async (x: { id: string; pages: string }) => {
    const response = await GraphQLConnector.request(UpdateChapter, {
      input: {
        data: { value: x.pages },
        where: { id: x.id },
      },
    });
    return response;
  });

export const useDeleteChapter = () =>
  useMutation('delete-chapter', async (id: string) => {
    const response = await GraphQLConnector.request(DeleteChapter, {
      input: {
        where: { id },
      },
    });
    return response;
  });

export const useGetOneChapter = (id: string) =>
  useQuery('get-one-chapter', async () => {
    const response = await GraphQLConnector.request(GetOneChapter, { id });
    return response;
  });
