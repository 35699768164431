/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';

import Flex from 'src/components/UI/elements/flex/Flex';
import { Header2 } from 'src/components/UI/elements/typography';

import Typography from './typography/Typography';

import endpoints from 'src/app/config/endpoints';
import parseImageURL from 'src/utils/image-utils';
import {
  useCreateAdminImage,
  useGetAdminImages,
  useGetStockPhotos,
} from 'src/graphql/hooks/imageHooks';

import {
  FileUploadContainer,
  FormField,
  ImageColumn,
  ImageRow,
  InputLabel,
  UploadFileBtn,
} from './styled';

const UploadImageSideMenu: React.FC<{
  onImageSelection: (v: string, id: any) => void;
}> = ({ onImageSelection }) => {
  const [selected, setSelected] = useState<string | null>(null);
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [error, setError] = useState('');
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>();
  const { mutateAsync: createImage } = useCreateAdminImage();
  const handleUploadBtnClick = () => {
    // @ts-ignore
    fileInputField?.current?.click();
  };

  useEffect(() => setFiles({}), []);
  const { data, isSuccess } = useGetStockPhotos();
  const { data: aImages, isSuccess: aImagesLoaded, refetch } = useGetAdminImages();
  const onDrop = async (e: any) => {
    e.preventDefault();
    const img = e.target.files[0];
    const fd = new FormData();
    fd.append('files', img);
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT || endpoints.BASE,
      timeout: 60 * 1000, // 1 min
    });
    await axios
      .post(`${endpoints.BASE}/upload`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
          setIsUpload(true);
          setUploadPercentage(
            // @ts-ignore
            parseInt(
              `${Math.round((progressEvent.loaded * 100) / progressEvent.total) || '0'}`,
              10
            )
          );
        },
      })
      .then((resp: AxiosResponse<any>) =>
        createImage(resp.data[0].id).then(() => refetch())
      )
      // eslint-disable-next-line no-shadow
      .catch((error: AxiosError) => console.log(error));
  };

  useEffect(() => {
    if (uploadPercentage === 100) {
      setIsUpload(false);
    }
  }, [uploadPercentage, isUpload]);

  return (
    <Flex column style={{ width: '50vw', height: '50vh' }}>
      <Flex column>
        <FileUploadContainer>
          <InputLabel>
            <Typography size={20} color='eggplant' bold>
              Image Upload
            </Typography>
            <Typography size={14} color='mountbatten' marginTop={5}>
              Upload your image to Admin Photos
            </Typography>
          </InputLabel>
          <UploadFileBtn
            type='button'
            // @ts-ignore
            onClick={handleUploadBtnClick}
          >
            <span> Upload image</span>
          </UploadFileBtn>
          {isUpload && <progress value={uploadPercentage} max={100} />}
          <FormField
            type='file'
            ref={fileInputField}
            // @ts-ignore
            onChange={onDrop}
            title=''
            value=''
          />
        </FileUploadContainer>
        <Typography size='sm' color='mountbatten' marginTop={5}>
          Supported files: JPEG, JPG, PNG, WebP
        </Typography>
        <Typography size='sm' color='coral' marginTop={5} marginBottom={5}>
          {error}
        </Typography>
      </Flex>
      <Flex row>
        <Flex column style={{ width: '50%' }}>
          <Header2>Stock photos</Header2>
          <ImageRow>
            {isSuccess &&
              data?.stockPhotos?.length &&
              Array(Math.ceil(data.stockPhotos.length / 5))
                .fill('')
                .map((_, index) => {
                  return (
                    <ImageColumn key={Math.random()}>
                      {data.stockPhotos.slice(index * 5, index * 5 + 5).map(
                        (image: {
                          id: string;
                          file: {
                            id: React.Key | null | undefined;
                            url: string | undefined;
                          };
                        }) => {
                          return (
                            <img
                              onClick={() => {
                                if (selected === image.file.url || '') {
                                  onImageSelection(image.file.url || '', image.file.id);
                                } else {
                                  setSelected(image.file.url || '');
                                }
                              }}
                              style={{
                                border:
                                  selected === image.file.url ? '2px solid blue' : '',
                              }}
                              key={`${image.id}-${new Date()}`}
                              src={parseImageURL(`${image.file.url}`)}
                              onKeyDown={() => setSelected(image.id)}
                              alt=''
                            />
                          );
                        }
                      )}
                    </ImageColumn>
                  );
                })}
          </ImageRow>
        </Flex>
        <Flex column style={{ width: '50%' }}>
          <Header2>Admin Photos</Header2>
          <ImageRow>
            {aImagesLoaded &&
              aImages?.adminImages?.length &&
              Array(Math.ceil(aImages.adminImages.length / 5))
                .fill('')
                .map((_, index) => {
                  return (
                    <ImageColumn key={Math.random()}>
                      {aImages.adminImages.slice(index * 5, index * 5 + 5).map(
                        (image: {
                          id: string;
                          file: {
                            id: React.Key | null | undefined;
                            url: string | undefined;
                          };
                        }) => {
                          return (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                            <img
                              onClick={() => {
                                if (selected === image.file.url || '') {
                                  onImageSelection(image.file.url || '', image.file.id);
                                } else {
                                  setSelected(image.file.url || '');
                                }
                              }}
                              style={{
                                border:
                                  selected === image.file.url ? '2px solid blue' : '',
                              }}
                              key={`${image.id}-${new Date()}`}
                              src={parseImageURL(`${image.file.url}`)}
                              alt=''
                            />
                          );
                        }
                      )}
                    </ImageColumn>
                  );
                })}
          </ImageRow>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UploadImageSideMenu;
