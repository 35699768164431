import React from 'react';

interface ISpreadIcon {
  inverted?: boolean;
}

export default function SpreadIcon({ inverted }: ISpreadIcon) {
  if (inverted) {
    return (
      <svg
        width='20'
        height='13'
        viewBox='0 0 20 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.75 13H11.25C10.5596 13 10 12.418 10 11.7001V1.30051C10 0.582571 10.5596 0.000566433 11.25 0.000566433H15.625C15.6306 -0.000188811 15.6363 -0.000188811 15.6419 0.000566433H15.6456C15.6515 0.00248909 15.6576 0.00379548 15.6638 0.00446626C15.7189 0.00814068 15.7733 0.0192844 15.8256 0.0376148H15.835H15.8444H15.8519C15.8634 0.0460162 15.8743 0.0553622 15.8844 0.0655636C15.9525 0.0970375 16.0145 0.14108 16.0675 0.195558L19.8175 4.09539C19.8699 4.1505 19.9122 4.21502 19.9425 4.28583C19.9481 4.30013 19.9525 4.31378 19.9569 4.32873L19.9631 4.34693C19.9806 4.40113 19.9909 4.45754 19.9937 4.51462C19.9943 4.5211 19.9958 4.52745 19.9981 4.53347V4.53737C19.9992 4.54163 19.9998 4.54598 20 4.55037V11.7001C20 12.0448 19.8683 12.3755 19.6339 12.6193C19.3995 12.863 19.0815 13 18.75 13ZM15.625 1.30051V4.55037H18.75L15.625 1.30051Z'
          fill='#51404C'
        />
        <path
          d='M1.25 13H8.75C9.44036 13 10 12.418 10 11.7001V1.30051C10 0.582571 9.44036 0.000566433 8.75 0.000566433H4.375C4.3694 -0.000188811 4.36373 -0.000188811 4.35812 0.000566433H4.35437C4.34847 0.00248909 4.3424 0.00379548 4.33625 0.00446626C4.28113 0.00814068 4.22672 0.0192844 4.17438 0.0376148H4.165H4.15563H4.14813C4.13659 0.0460162 4.12573 0.0553622 4.11562 0.0655636C4.04753 0.0970375 3.98549 0.14108 3.9325 0.195558L0.1825 4.09539C0.130116 4.1505 0.0877647 4.21502 0.0574999 4.28583C0.0518751 4.30013 0.0475006 4.31378 0.0431252 4.32873L0.0368748 4.34693C0.0194263 4.40113 0.00912476 4.45754 0.00625038 4.51462C0.00570679 4.5211 0.00423145 4.52745 0.00187492 4.53347V4.53737C0.000849724 4.54163 0.000221252 4.54598 0 4.55037V11.7001C0 12.0448 0.131697 12.3755 0.366117 12.6193C0.600537 12.863 0.918479 13 1.25 13ZM4.375 1.30051V4.55037H1.25L4.375 1.30051Z'
          fill='#FFFCF9'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='13'
      viewBox='0 0 20 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.75 13H11.25C10.5596 13 10 12.418 10 11.7001V1.30051C10 0.582571 10.5596 0.000566433 11.25 0.000566433H15.625C15.6306 -0.000188811 15.6363 -0.000188811 15.6419 0.000566433H15.6456C15.6515 0.00248909 15.6576 0.00379548 15.6638 0.00446626C15.7189 0.00814068 15.7733 0.0192844 15.8256 0.0376148H15.835H15.8444H15.8519C15.8634 0.0460162 15.8743 0.0553622 15.8844 0.0655636C15.9525 0.0970375 16.0145 0.14108 16.0675 0.195558L19.8175 4.09539C19.8699 4.1505 19.9122 4.21502 19.9425 4.28583C19.9481 4.30013 19.9525 4.31378 19.9569 4.32873L19.9631 4.34693C19.9806 4.40113 19.9909 4.45754 19.9937 4.51462C19.9943 4.5211 19.9958 4.52745 19.9981 4.53347V4.53737C19.9992 4.54163 19.9998 4.54598 20 4.55037V11.7001C20 12.0448 19.8683 12.3755 19.6339 12.6193C19.3995 12.863 19.0815 13 18.75 13ZM15.625 1.30051V4.55037H18.75L15.625 1.30051Z'
        fill='#FFFCF9'
      />
      <path
        d='M1.25 13H8.75C9.44036 13 10 12.418 10 11.7001V1.30051C10 0.582571 9.44036 0.000566433 8.75 0.000566433H4.375C4.3694 -0.000188811 4.36373 -0.000188811 4.35812 0.000566433H4.35437C4.34847 0.00248909 4.3424 0.00379548 4.33625 0.00446626C4.28113 0.00814068 4.22672 0.0192844 4.17438 0.0376148H4.165H4.15563H4.14813C4.13659 0.0460162 4.12573 0.0553622 4.11562 0.0655636C4.04753 0.0970375 3.98549 0.14108 3.9325 0.195558L0.1825 4.09539C0.130116 4.1505 0.0877647 4.21502 0.0574999 4.28583C0.0518751 4.30013 0.0475006 4.31378 0.0431252 4.32873L0.0368748 4.34693C0.0194263 4.40113 0.00912476 4.45754 0.00625038 4.51462C0.00570679 4.5211 0.00423145 4.52745 0.00187492 4.53347V4.53737C0.000849724 4.54163 0.000221252 4.54598 0 4.55037V11.7001C0 12.0448 0.131697 12.3755 0.366117 12.6193C0.600537 12.863 0.918479 13 1.25 13ZM4.375 1.30051V4.55037H1.25L4.375 1.30051Z'
        fill='#51404C'
      />
    </svg>
  );
}
