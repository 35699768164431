import { gql } from 'graphql-request';

export const DeleteImageMutation = gql`
  mutation DeleteImage($input: deleteFileInput!) {
    deleteFile(input: $input) {
      file {
        id
      }
    }
  }
`;
