import React, { SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { InlineSpinner } from './spinners';

import { FiChevronLeft } from 'react-icons/fi';
import MainTheme from 'src/theme/defaultTheme';

export const BaseButton = styled.button``;

interface ButtonProps {
  loading?: boolean;
  value?: string;
  handleClick?: Function;
  disabled?: boolean;
}

interface ThemedButtonProps extends ButtonProps {
  theme: typeof MainTheme;
}

const BaseLoginButton = styled(BaseButton)`
  background-color: ${(props: ThemedButtonProps) => props.theme.colors.mountbatten};
  font-size: ${(props: ThemedButtonProps) => props.theme.fontSizes.paragraph};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  font-weight: normal;
  border: 1px solid ${(props: ThemedButtonProps) => props.theme.colors.mountbatten};
  border-radius: 8px;
  width: 100%;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.eggplant};
  }
`;

const BaseSubmitButton = styled.input.attrs({ type: 'submit' })`
  background-color: ${(props: ThemedButtonProps) => props.theme.colors.mountbatten};
  font-size: ${(props: ThemedButtonProps) => props.theme.fontSizes.paragraph};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  font-weight: normal;
  border: 1px solid ${(props: ThemedButtonProps) => props.theme.colors.mountbatten};
  border-radius: 8px;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.eggplant};
  }
`;

const BaseLoadingButton = styled.button<{ loadings?: boolean }>`
  background-color: ${({ theme }) => theme.colors.mountbatten};
  font-size: ${({ theme }) => theme.fontSizes.paragraph};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  font-weight: 700;
  border: 1px solid ${({ theme }) => theme.colors.mountbatten};
  border-radius: 8px;
  ${({ loadings }) =>
    loadings &&
    css`
      background-color: ${({ theme }) => theme.colors.eggplant};
    `}
  &:hover,
      &:focus {
    background-color: ${({ theme }) => theme.colors.eggplant};
  }
`;

export const SubmitButton: React.FC<ButtonProps> = ({ loading, handleClick, value }) => {
  return loading ? (
    <BaseLoadingButton loadings={loading}>
      <InlineSpinner color={MainTheme.colors.white} />
    </BaseLoadingButton>
  ) : (
    <BaseSubmitButton handleClick={handleClick} value={value} />
  );
};

export const LoginButton: React.FC<ButtonProps> = ({
  children,
  loading,
  handleClick,
}) => {
  return loading ? (
    <BaseLoadingButton>
      <InlineSpinner />
    </BaseLoadingButton>
  ) : (
    <BaseLoginButton onClick={(e: any) => handleClick && handleClick?.(e)}>
      {children}
    </BaseLoginButton>
  );
};

const BaseOAuthButton = styled.button`
  position: relative;
  background-color: white;
  font-size: ${(props: ThemedButtonProps) => props.theme.fontSizes.paragraph};
  color: ${(props: ThemedButtonProps) => props.theme.colors.eggplant};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  border: 1px solid ${(props: ThemedButtonProps) => props.theme.colors.mountbatten};
  transition: 0.2s;
  border-radius: 8px;
  svg {
    position: absolute;
    left: 2rem;
    height: 24px;
    width: 24px;
  }
  &:hover {
    background-color: ${(props: ThemedButtonProps) => props.theme.colors.mountbatten};
    transition: 0.2s;
    color: white;
    svg {
      color: white;
    }
  }
`;

export const OAuthButton: React.FC<ButtonProps> = ({
  loading,
  value,
  children,
  handleClick,
}) => {
  return loading ? (
    <BaseLoadingButton>
      <InlineSpinner />
    </BaseLoadingButton>
  ) : (
    <BaseOAuthButton onClick={(e: any) => handleClick && handleClick?.(e)} value={value}>
      {children}
    </BaseOAuthButton>
  );
};

interface IBlockButton {
  theme: typeof MainTheme;
  invert?: boolean;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const BlockButton = styled.button`
  border-radius: 8px;
  border: none;
  height: 40px;
  cursor: pointer;
  transition: 0.1s;
  outline: none;
  padding: 5px 15px;
  min-width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  opacity: ${(props: IBlockButton) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props: IBlockButton) => (props.disabled ? 'none' : 'all')};
  background-color: ${(props: IBlockButton) => {
    if (props.invert) return 'transparent';
    if (props.loading) return props.theme.colors.mountbattenLight;
    return props.theme.colors.mountbatten;
  }};
  color: ${(props: IBlockButton) =>
    props.invert || props.loading
      ? props.theme.colors.mountbatten
      : props.theme.colors.babypowder};

  &:hover {
    transition: 0.1s;
    color: ${({ theme }) => theme.colors.babypowder};
    background-color: ${({ theme }) => theme.colors.eggplant};
  }
`;
export const InvertedBlockButton = styled.button`
  border-radius: 8px;
  border: none;
  height: 40px;
  cursor: pointer;
  transition: 0.1s;
  outline: none;
  padding: 5px 15px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.mountbatten : theme.colors.mountbattenLight};
  color: ${(props: IBlockButton) =>
    !props.invert ? props.theme.colors.mountbatten : props.theme.colors.babypowder};

  ${({ active }) => `
    svg {
      rect,
      path {
        fill: ${active && 'white'};
      }
      circle,
      path {
        fill:  ${active && 'white'};
      }
    }
    `}

  &:hover,
  &:focus {
    transition: 0.1s;
    color: white;
    background-color: ${({ theme }) => theme.colors.mountbatten};
    svg {
      rect,
      path {
        fill: white;
      }
      circle,
      path {
        fill: white;
      }
    }
  }
`;

export const StyledNavlink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0 2rem;
  color: ${({ theme }) => theme.colors.eggplant};
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const StyledBackLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.eggplant};
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
export const StyledLink = styled.a`
  display: flex;
  cursor: pointer;
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.eggplant};
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const BaseContinueButton = styled.button`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.mountbatten};
  border-radius: 8px;
  height: 50px;
  padding: 12px;
  width: 100%;
  display: block;
  color: white;
  font-weight: normal;
  cursor: pointer;
  transition: 0.1s;
  border: none;
  max-width: 415px;
  z-index: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colors.eggplant};
    transition: 0.1s;
  }
`;

export const BaseButtonWithBorder = styled.button`
  text-align: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.mountbatten};
  height: 50px;
  max-width: 415px;
  width: 100%;
  margin: 0 auto;
  display: block;
  color: ${({ theme }) => theme.colors.mountbatten};
  font-weight: normal;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.eggplant};
    transition: 0.1s;
    color: white;
  }
`;

export const FloatingHomeButton = () => {
  return (
    <StyledNavlink to='/overview'>
      <FiChevronLeft size='25' /> Home
    </StyledNavlink>
  );
};
export const FloatingBackButton = ({
  title = 'Back',
  handleGoBack,
}: {
  title?: string;
  handleGoBack?: (e: SyntheticEvent) => void;
}) => {
  const history = useHistory();
  const handleGoBackClick = handleGoBack || (() => history.goBack());
  return (
    <StyledBackLink href='#' onClick={handleGoBackClick}>
      <FiChevronLeft size='25' /> {title}
    </StyledBackLink>
  );
};

const emptyFn = () => {};
export const FloatingButton = ({
  title = 'Next & Save',
  onClick = emptyFn,
}: {
  title?: string;
  onClick?: () => void;
}) => {
  return (
    <StyledLink href='#' onClick={() => onClick()}>
      {title}
    </StyledLink>
  );
};

interface IContinueButtonProps {
  buttonLink: string;
  onClick?: () => void;
}

export const ContinueButton = ({ buttonLink, onClick }: IContinueButtonProps) => {
  const history = useHistory();

  return (
    <BaseContinueButton
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onClick && onClick();
        history.push(buttonLink);
      }}
    >
      Continue
    </BaseContinueButton>
  );
};

export const DashboardBackButton = styled(BaseContinueButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0;
  max-width: 120px;
`;
