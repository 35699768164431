export const ROUTES = {
  landing: '/',
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  overview: '/overview',
  myProfile: '/my-profile',
  createLayout: '/layout-create',
  editLayout: '/layout-edit/:id',
  layoutList: '/layout-list',
  pageCreate: '/page-create/',
  pageEdit: '/page-edit/:id',
  pageList: '/page-list',
  editMagazine: '/magazine/edit/:id',
  chapterCreate: '/chapter-create/',
  chapterEdit: '/chapter-edit/:id',
  chapterList: '/chapter-list',
  editor: '/editor',
  resetPassword: '/reset-password',
  emailConfirmation: '/email-confirmation',
  privacyPolicy: '/privacy-policy',
  sharedMagazine: '/magazine/share/:id',
};

//
export const TOOL_BAR_HEIGHT = 100;
export const TOOL_BAR_BORDER_HEIGHT = 5;

export const HISTORY_ACTIONS = {
  create: 'create',
  detele: 'delete',
  update: 'update',
};
export const HISTORY_LIMIT = 10;
export const HISTORY_HIGHLIGHTED_ITEM_ANIMATION_TIMING = 3; // seconds

// Page-Layout Editor
export const BACKGROUND_ZINDEX = 1;
export const MAGAZINE_CREATOR_SIDE_BAR_WIDTH = 275;
export const MINIMUM_SUPPORTED_RESOLUTION = 1024;
export const SUBS_TYPES = {
  ANNUAL: 'Annual Subscription',
  MONTHY: 'Monthly Subscription',
  FREE: 'Free Trial',
};
export const MAX_ALLOWED_ZOOM = 5;
export const MIN_ALLOWED_ZOOM = 1;


export const SUBSCRIPTION_IDS = {
  6893565673667: SUBS_TYPES.ANNUAL,
  6893565313219: SUBS_TYPES.MONTHY,
  6893562757315: SUBS_TYPES.FREE,
};

// Free Trial Config
export const MAX_ALLOWED_CHAPTERS = 5;
export const UPGRADE_SUB_LINK = 'https://www.lifeazine.com/products/annual-subscription';
export const MAIN_SITE =
  process.env.REACT_APP_SHOPIFY_MEMBERSHIP_URL || 'https://www.lifeazine.com';
export default {};
