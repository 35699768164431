import { gql } from 'graphql-request';

export const GetUserDataForResumeLogin = gql`
  query GetUserForResumeLogin($userID: ID!) {
    user(id: $userID) {
      id
      username
      email
      firstName
      lastName
      custom_role
      customer_id
      onboarded
    }
  }
`;

export const GetUserData = gql`
  query ($userID: ID!) {
    user(id: $userID) {
      username
      email
      firstName
      lastName
      email
      dateOfBirth
      gender
      phone
      address
      zipCode
      city
      country
      profilePicture {
        url
      }
      customer_id
      onboarded
    }
  }
`;

export const GetProfilePicture = gql`
  query ($userID: ID!) {
    user(id: $userID) {
      profilePicture {
        url
      }
    }
  }
`;
