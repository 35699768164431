/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function TextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="22"
        height="22"
        x="1"
        y="1"
        stroke="#A0889A"
        strokeWidth="2"
        rx="3.022"
       />
      <path
        fill="#A0889A"
        d="M7.136 6.752h9.604l.091 2.89h-.407c-.199-.908-.454-1.498-.763-1.769-.305-.277-.95-.415-1.934-.415h-.947v8.7c0 .658.103 1.067.307 1.228.205.155.653.257 1.345.307V18H9.477v-.307c.719-.056 1.167-.174 1.344-.357.177-.183.266-.642.266-1.378v-8.5h-.946c-.941 0-1.583.136-1.926.407-.338.27-.592.863-.764 1.776h-.415l.1-2.889z"
       />
    </svg>
  );
}
