import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import MainTheme from 'src/theme/defaultTheme';

export const BaseInput = styled.input`
  color: ${({ theme }) => theme.colors.eggplant};
  border: 1px solid ${({ theme }) => theme.colors.mountbatten};
  border-radius: 8px;
  padding: 18px 16px;
  margin: 12px 0;
  &::placeholder {
  }
`;

export const RoundedInput = styled(BaseInput)`
  border-radius: 5px;
  margin: 12px 0;
  width: 100%;
  background-color: white;
`;

export const BaseTextArea = styled.textarea`
  background-color: ${({ theme }) => theme.colors.babypowder};
  color: ${({ theme }) => theme.colors.eggplant};
  border: 1px solid ${({ theme }) => theme.colors.mountbatten};
`;

export const BaseInputLabel = styled.span`
  color: ${({ theme }) => theme.textColors.p};
`;

export const CheckboxLabel = styled.label`
  color: ${({ theme }) => theme.textColors.p};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 1em;
  input {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

interface StyledCheckboxProps {
  checked: boolean;
  theme: typeof MainTheme;
}

const StyledCheckboxOuter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-color: ${(props: StyledCheckboxProps) => props.theme.colors.mountbatten};
  transition: all 150ms;
  div {
    background-color: ${(props: StyledCheckboxProps) =>
      props.checked ? props.theme.colors.mountbatten : 'white'};
  }
`;
const StyledCheckboxInner = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  transition: all 150ms;
`;

interface CheckboxProps {
  handleChange: Function;
  checked: boolean;
  label?: string;
  children?: any;
}

export const BaseCheckBox: React.FC<CheckboxProps> = ({
  checked,
  handleChange,
  children,
}) => {
  return (
    <CheckboxLabel>
      <StyledCheckboxOuter checked={checked}>
        <StyledCheckboxInner />
      </StyledCheckboxOuter>
      <input
        type='checkbox'
        onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
          handleChange(ev.target.checked)
        }
      />
      {children}
    </CheckboxLabel>
  );
};
