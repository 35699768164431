import React from 'react';
import { RotateSpinner } from 'react-spinners-kit';
import styled from 'styled-components';

import { mainColors } from 'src/theme/defaultTheme';

const PageSpinnerWrapper = styled.div`
  height: 100%;
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullPageSpinnerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageSpinner: React.FC = () => (
  <PageSpinnerWrapper>
    <RotateSpinner size={60} color={mainColors.eggplant} />
  </PageSpinnerWrapper>
);
export const FullPageSpinner: React.FC = () => (
  <FullPageSpinnerWrapper>
    <RotateSpinner size={60} color={mainColors.eggplant} />
  </FullPageSpinnerWrapper>
);

export const InlineSpinner: React.FC<{size?:number,color?:string}> = ({size=20,color=mainColors.mountbatten}) => {
  return <RotateSpinner size={size} color={color} />;
};
