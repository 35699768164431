import { gql } from 'graphql-request';

export const UpdateLayout = gql`
  mutation ($input: updateLayoutInput) {
    updateLayout(input: $input) {
      layout {
        name
        id
        pageType
        created_at
        updated_at
        blocks {
          id
          x
          y
          width
          height
          layer
          backgroundColor
          ident
        }
      }
    }
  }
`;
