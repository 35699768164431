/**
 * @typedef {Function}DebounceFunc
 * @param {Function} callback
 * @param {number} delay
 * @returns {Function} function with delayed callback
 */
 function debounce (callback: Function, delay: number) {
  let timer: NodeJS.Timeout;
  return function nameless(this: Function, ...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
};
/**
 *
 * @param {number} delay - miliseconds (1000=1 second)
 * @returns {Function} function with debounced delay
 */
const useDebounce = (delay: number) => {
  return (callback: Function) => debounce(callback, delay);
};

export default useDebounce;
