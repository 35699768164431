import { gql } from 'graphql-request';

export const CreatePage = gql`
  mutation ($input: createPageInput) {
    createPage(input: $input) {
      page {
        id
        created_at
        updated_at
        name
        layout {
          id
          pageType
          created_at
          updated_at
          name
          blocks {
            id
            x
            y
            width
            height
            layer
            backgroundColor
            ident
          }
        }
      }
    }
  }
`;
