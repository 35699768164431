import { gql } from 'graphql-request';

export const GetAllPages = gql`
  query {
    pages(sort: "name:asc") {
      id
      created_at
      updated_at
      name
      background
      writingTips
      layout {
        id
        pageType
        created_at
        updated_at
        name
        blocks {
          id
          x
          y
          width
          height
          layer
          backgroundColor
          ident
        }
      }
    }
  }
`;
export const GetOnePage = gql`
  query ($id: ID!) {
    page(id: $id) {
      id
      writingTips
      created_at
      updated_at
      name
      value
      background
      layout {
        id
        pageType
        created_at
        updated_at
        name
        blocks {
          id
          x
          y
          width
          height
          layer
          backgroundColor
          ident
          content {
            values
          }
        }
      }
    }
  }
`;
