import styled, { css } from 'styled-components';

import { FlexProps } from './flex.interface';

export const FlexStyled = styled.div<FlexProps>`
  display: flex;
  position: relative;
  ${({
    row,
    column,
    center,
    between,
    alignCenter,
    fstart,
    baseline,
    fend,
    flex,
    height,
    width,
    marginHorizontal,
    marginVertical,
  }) => css`
    ${!!height &&
    css`
      height: ${typeof height === 'string' ? height : `${height}px`};
    `}
    ${!!width &&
    css`
      width: ${typeof width === 'string' ? width : `${width}px`}; ;
    `}
    ${row &&
    css`
      flex-direction: row;
    `}
    ${row &&
    css`
      flex-direction: row;
    `}
    ${column &&
    css`
      flex-direction: column;
    `}
    ${center &&
    css`
      justify-content: center;
    `}
    ${fstart &&
    css`
      justify-content: flex-start;
    `}
    ${fend &&
    css`
      justify-content: flex-end;
    `}
    ${alignCenter &&
    css`
      align-items: center;
    `}
    ${baseline &&
    css`
      align-items: baseline;
    `}
    ${between &&
    css`
      justify-content: space-between;
    `}
    ${flex &&
    css`
      flex: ${flex};
    `} ${(marginHorizontal || marginVertical) &&
    css`
      margin: ${marginVertical || 0}px
        ${marginHorizontal || 0}px;
    `}
  `}
`;
