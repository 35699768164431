/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function ChaptersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      fill="none"
      viewBox="0 0 30 31"
    >
      <path
        stroke="#A0889A"
        strokeWidth="2"
        d="M3.478 22.956a2 2 0 01-2-2V3a2 2 0 012-2h23a2 2 0 012 2v17.956a2 2 0 01-2 2h-5.002a4 4 0 00-3.17 1.56l-1.733 2.252a2 2 0 01-3.174-.007l-1.704-2.232a4 4 0 00-3.18-1.573H3.479zM8 8.479h13.87M8.043 13.479h7.25"
       />
    </svg>
  );
}
