import styled from 'styled-components';

export const EggPlantButton = styled.button`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.md};
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.eggplant};
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    filter: brightness(40%);
  }
`;
export const EggPlantSubmit = styled.input`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.md};
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.eggplant};
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  &:hover,
  &:focus {
    filter: brightness(40%);
  }
`;

export const MenuButton = styled.button`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.mountbatten};
  border-radius: 8px;
  height: 50px;
  max-width: 415px;
  width: 100%;
  margin: 10px;
  display: block;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: 0.1s;
  border: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.eggplant};
    transition: 0.1s;
  }
`;
export const CircleButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40px;
  position: fixed;
  bottom: 35px;
  left: 135px;
  border-radius: 50px;
  background-color: #51404c;
  z-index: 9;
  &:hover {
    cursor: pointer;
    background-color: rgba(81, 64, 76, 0.5);
  }
`;
export const DeleteButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 25px;
  height: 25px;
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  &:hover {
    cursor: pointer;
    background-color: rosybrown;
  }
`;

export const NewMagazineButton = styled.button`
  background-color: ${({ theme }) => theme.colors.eggplant};
`;
