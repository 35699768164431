/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function ImagesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="22"
        height="22"
        x="1"
        y="1"
        stroke="#A0889A"
        strokeWidth="2"
        rx="3.022"
       />
      <path
        fill="#A0889A"
        d="M19 18H5l3.5-4.21 1.313 1.263 3.5-5.053L19 18z"
       />
      <circle cx="7" cy="8" r="2" fill="#A0889A" />
    </svg>
  );
}
