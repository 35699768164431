import styled, { css } from 'styled-components';

import { StyledContainerProps } from './scalingContainer.interface';

export const StyledContainer = styled.div<StyledContainerProps>`
  ${({ scale, float }) => css`
    transform: scale(${scale});

    ${float === 'LEFT' &&
    css`
      transform-origin: top left;
    `} ${float === 'RIGHT' &&
    css`
      transform-origin: top right;
    `}    ${float === 'CENTER' &&
    css`
      transform-origin: top left;
    `}
  `}
`;

export const ExternalContainer = styled.div`
  position: relative;
  flex: 1;
`;
export const ContentAbsolute = styled.div<
  StyledContainerProps & { scaledWidth: number }
>`
  position: absolute;
  top: 0;
  ${({ float, scaledWidth }) => css`
    ${float === 'LEFT' &&
    css`
      left: 0;
    `} ${float === 'RIGHT' &&
    css`
      right: 0;
    `}    ${float === 'CENTER' &&
    css`
      left: ${scaledWidth}px;
    `}
  `}/* left:calc(-50% - 192px) ; */
`;
