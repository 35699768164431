/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function ListViewIcon({ fill = '#60505E' }) {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='14' height='3' fill={fill} />
      <rect y='6' width='14' height='3' fill={fill} />
      <rect y='12' width='14' height='3' fill={fill} />
    </svg>
  );
}
