/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function NextIcon() {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.06055 7.46865C8.32422 7.20498 8.32422 6.76553 8.06055 6.50186L2.37695 0.788966C2.08398 0.525294 1.64453 0.525294 1.38086 0.788966L0.707031 1.46279C0.443359 1.72647 0.443359 2.16592 0.707031 2.45889L5.21875 6.9999L0.707031 11.5116C0.443359 11.8046 0.443359 12.244 0.707031 12.5077L1.38086 13.1815C1.64453 13.4452 2.08398 13.4452 2.37695 13.1815L8.06055 7.46865Z"
        fill="#A0889A"
      />
    </svg>
  );
}
