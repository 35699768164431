import React from 'react';
import { useLocation } from 'react-router-dom';

import { MenuBarWrapper, MenuItem, MenuItemTitle } from './styled/MenuBarComponents';

import { ROUTES } from 'src/app/config/constants';

import icons from 'src/theme/icons';

const menuItems = [
  {
    title: 'Layouts',
    icon: icons.MENU_ICON_PAGES,
    link: ROUTES.layoutList,
  },
  {
    title: 'Pages',
    icon: icons.MENU_ICON_CHAPTERS,
    link: ROUTES.pageList,
  },
  {
    title: 'Chapters',
    icon: icons.MENU_ICON_IMAGES,
    link: ROUTES.chapterList,
  },
  // {
  //   title: "Text",
  //   icon: icons.MENU_ICON_TEXT,
  //   link: "/my-magazine/text",
  // },
  {
    title: 'Magazines',
    icon: icons.MENU_ICON_EXPORT,
    link: '/my-magazine/export',
  },
];

const MenuBar: React.FC = () => {
  const location = useLocation();

  return (
    <MenuBarWrapper>
      {menuItems.map((item) => {
        const active = location.pathname.includes(item.link);
        return (
          <MenuItem active={`${active}`} to={item.link} key={item.link}>
            <item.icon />
            <MenuItemTitle>{item.title}</MenuItemTitle>
          </MenuItem>
        );
      })}
    </MenuBarWrapper>
  );
};
export default MenuBar;
