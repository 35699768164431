import React from 'react';

import {
  TypographyProps,
} from './typography.interface';

import { TextStyled } from './styled';

// Start Helper - get font Size

// const getPixelSize = (font: keyof typeof fontSizes | number | undefined) => {
//   if (!font) {
//     return;
//   }
//   if (typeof font === 'string') {
//     // eslint-disable-next-line consistent-return
//     return Number(fontSizes[font].slice(0, -2));
//   }
//   if (typeof font === 'number') {
//     // eslint-disable-next-line consistent-return
//     return font;
//   }
// };

// End helper


const Typography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {

  // const { current, title, rows } = props;
  // const [clicked, setClicked] = useState(false);
  // const focused = !!selected && selected.title === title;

  // if (focused && current === editableChapter && clicked && !disabledClick) {
  //   return (
  //     <InteractiveTypography
  //       value={selected.value}
  //       setClicked={setClicked}
  //       handleChange={(value) => setActiveQuestion({ ...selected, value })}
  //       {...props}
  //       rows={rows || 1}
  //     />
  //   );
  // }

  return (
    <TextStyled
      {...props}
      current={false}
      focused={false}
      onClick={()=>{}}>
      {children}
    </TextStyled>
  );
};

export default Typography;
