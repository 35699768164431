import { gql } from 'graphql-request';

export const CreateAdminImage = gql`
  mutation ($input: createAdminImageInput) {
    createAdminImage(input: $input) {
      adminImage {
        id
        file {
          id
        }
      }
    }
  }
`;
