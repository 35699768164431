import { useMutation, useQuery } from 'react-query';

import { UpdateLayout } from '../mutations/layout-mutations/UpdateLayout.gql';
import { CreatePage } from '../mutations/page-mutations/CreatePage.gql';
import { DeletePage } from '../mutations/page-mutations/DeletePage.gql';
import { UpdatePage } from '../mutations/page-mutations/UpdatePage.gql';
import { GetAllPages, GetOnePage } from '../queries/PageQueries.gql';

import GraphQLConnector from 'src/graphql/GraphQLClient';

import { PageType } from 'src/types/page.interface';

export const useCreatePage = (page: PageType) =>
  useMutation('create-page', async () => {
    const response = await GraphQLConnector.request(CreatePage, {
      input: {
        data: page,
      },
    });
    return response;
  });
export const useUpdatePageLayout = () =>
  useMutation('update-page-layout', async (x: { id: string; layoutId: string }) => {
    const response = await GraphQLConnector.request(UpdatePage, {
      input: {
        data: { layout: x.layoutId },
        where: { id: x.id },
      },
    });
    return response;
  });
export const useUpdatePageContent = () =>
  useMutation('update-page-content', async (x: { layoutId: string; blocks: string }) => {
    const response = await GraphQLConnector.request(UpdateLayout, {
      input: {
        data: { blocks: x.blocks },
        where: { id: x.layoutId },
      },
    });
    return response;
  });
export const useUpdatePageName = () =>
  useMutation('update-page-content', async (x: { id: string; name: string }) => {
    const response = await GraphQLConnector.request(UpdatePage, {
      input: {
        data: { name: x.name },
        where: { id: x.id },
      },
    });
    return response;
  });
export const useUpdatePageWritingTips = () =>
  useMutation(
    'update-page-writing-tips',
    async (x: { id: string; writingTips: string }) => {
      const response = await GraphQLConnector.request(UpdatePage, {
        input: {
          data: { writingTips: x.writingTips },
          where: { id: x.id },
        },
      });
      return response;
    }
  );
export const useUpdatePageBackground = () =>
  useMutation('update-page-background', async (x: { id: string; content: string }) => {
    const response = await GraphQLConnector.request(UpdatePage, {
      input: {
        data: { background: x.content },
        where: { id: x.id },
      },
    });
    return response;
  });
export const useDeletePage = () =>
  useMutation('delete-page', async (id: string) => {
    const response = await GraphQLConnector.request(DeletePage, {
      input: {
        where: { id },
      },
    });
    return response;
  });
export const useGetPages = () =>
  useQuery('get-all-pages', async () => {
    const response = await GraphQLConnector.request(GetAllPages);
    return response;
  });
export const useGetOnePage = (id: string) =>
  useQuery('get-one-layout', async () => {
    const response = await GraphQLConnector.request(GetOnePage, { id });
    return response;
  });
