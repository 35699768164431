import endpoints from 'src/app/config/endpoints';
import internalLinks from 'src/app/config/internal_links';

const useGenerateImageUrl = () => {
  const generateImageUrl = (chapter: any) => {
    if (chapter?.chapterSplash?.url) {
      return `${endpoints.BASE}${chapter.chapterSplash?.url}`;
    }
    return internalLinks.media.placeholderImage;
  };

  return generateImageUrl;
};

export default useGenerateImageUrl;
