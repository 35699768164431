import { useState, useEffect } from "react";
import useDebounce from "src/utils/useDebounce";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);
  const debounce = useDebounce(250)
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = debounce(() => setMatches(media.matches));
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [debounce, matches, query]);

  return matches;
}

export default useMediaQuery;
