import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link, useParams } from 'react-router-dom';

import UploadImageSideMenu from 'src/components/presetEditor/pageEditor/ImageUpload/upload-image-side-menu';
// import BackgroundElement from 'src/components/presetEditor/pageEditor/BackgroundElement';
// import ContentBlock from 'src/components/presetEditor/pageEditor/ContentBlock';
// import ImageElement from 'src/components/presetEditor/pageEditor/ImageElement';
import PageBlock from 'src/components/presetEditor/pageEditor/PageBlock';
// import TextElement from 'src/components/presetEditor/pageEditor/TextElement';
import { EggPlantButton } from 'src/components/UI/buttons.styled';
import Flex from 'src/components/UI/elements/flex/Flex';
import { BaseInput, BaseTextArea } from 'src/components/UI/elements/inputs';
import { Header6 } from 'src/components/UI/elements/typography';
import { PageSpinner } from 'src/components/UI/spinners.styled';
import WithEditorMenu from 'src/components/wrappers/withEditorMenu';

import { ButtonWrap } from '../../my-profile/MyProfileComponents';

import GraphQLConnector from 'src/graphql/GraphQLClient';
import { UpdateChapter } from 'src/graphql/mutations/chapter-mutations/UpdateChapter.gql';
import { ROUTES } from 'src/app/config/constants';
import endpoints from 'src/app/config/endpoints';
import useGenerateImageUrl from 'src/services/hooks/useGenerateImageUrl';
// import endpoints from 'src/app/config/endpoints';
import { useGetOneChapter } from 'src/graphql/hooks/chapterHooks';
import {
  useGetPages,
  // useUpdatePageBackground,
  // useUpdatePageContent,
  // useUpdatePageLayout,
} from 'src/graphql/hooks/pageHooks';

import { BlockType } from 'src/types/layout.interface';
import { PageType } from 'src/types/page.interface';
import { pageTypes } from 'src/theme/defaultTheme';

import {
  // CollapseButton,
  // ControlPanelBlock,
  LayoutControlPanel,
  LayoutCreatorContainer,
  LayoutWorkingPad,
  ScaleFlexContainer,
} from './styled';
import ScalingContainer from 'src/components/presetEditor/scalingContainer/ScalingContainer';

const customStyles = {
  overlay: {
    border: '3px solid black',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ChapterEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedPages, setSelectedPages] = useState<PageType[] | []>([]);
  const [selectedImage, setSelectedImage] = useState<{ url: string; id: any } | null>(
    null
  );
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [chapterName, setChapterName] = useState<string>('');
  const [chapterDescription, setChapterDescription] = useState<string>('');
  const { data, isSuccess, refetch } = useGetOneChapter(`${id}`);
  const generateImageUrl = useGenerateImageUrl();

  const { data: pageData, isSuccess: pagesLoaded } = useGetPages();

  useEffect(() => {
    if (data?.chapter?.title) {
      setChapterName(data.chapter.title);
    }
    if (data?.chapter?.description) {
      setChapterDescription(data.chapter.description);
    }
    if (data?.chapter?.pages) {
      setSelectedPages(data.chapter.pages);
    }
  }, [data]);

  if (!isSuccess && !pagesLoaded) {
    return null;
  }

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const gqlInput = {
      input: {
        data: {
          title: chapterName,
          description: chapterDescription,
          pages: selectedPages?.map?.((p) => p.id) ?? [],
        },
        where: { id: data.chapter.id },
      },
    };

    if (selectedImage?.id) {
      // @ts-ignore:next-line
      gqlInput.input.data.chapterSplash = selectedImage.id;
    }

    // Eventually we need to refactor this to be more clean. For example: put in separate service file
    await GraphQLConnector.request(UpdateChapter, gqlInput);

    refetch();
  };

  return (
    <WithEditorMenu>
      <Flex row flex={1}>
        <LayoutCreatorContainer>
          <LayoutControlPanel>
            <Flex row>
              <Link
                style={{ fontSize: 16, margin: 20, textDecoration: 'underline' }}
                to={ROUTES.chapterList}
              >
                {'<<'} back
              </Link>
            </Flex>

            <Flex column alignCenter>
              <Header6>Chapter Splash Image</Header6>
              <ButtonWrap
                onClick={() => {
                  if (!selectedImage) {
                    setModalIsOpen(true);
                  }
                }}
              >
                <img
                  style={{ margin: '1em auto' }}
                  src={selectedImage?.url ?? generateImageUrl(data?.chapter)}
                  alt={chapterName}
                />
                <span className='hidden-text'>Replace</span>
              </ButtonWrap>
            </Flex>
            <Flex center alignCenter>
              <div
                style={{
                  margin: '1rem',
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  width: '100%',
                }}
              >
                <BaseInput
                  placeholder='Add a chapter name.'
                  type='text'
                  value={chapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                />
                <BaseTextArea
                  placeholder='Add a chapter description.'
                  style={{ minHeight: '200px', padding: '16px', lineHeight: '20px' }}
                  value={chapterDescription}
                  onChange={(e) => setChapterDescription(e.target.value)}
                />
              </div>
            </Flex>
          </LayoutControlPanel>
        </LayoutCreatorContainer>

        <Flex column style={{ width: '50vw', padding: '1rem' }}>
          <Flex row alignCenter style={{ justifyContent: 'space-between' }}>
            <Header6>Selected Pages in Chapter</Header6>
            <EggPlantButton onClick={handleFormSubmit}>Save Chapter</EggPlantButton>
          </Flex>
          {selectedPages?.length > 0 ? (
            <Flex row>
              {selectedPages.map((page) => (
                <Flex column key={page.id}>
                  <p>{page.name}</p>
                  <ScaleFlexContainer
                    pageType={page.layout?.pageType}
                    onClick={async () => {
                      setSelectedPages(selectedPages.filter((p) => p.id !== page.id));
                    }}
                  >
                    <ScalingContainer
                      size={100}
                      float='CENTER'
                      defaultWidth={
                        page.layout?.pageType === 'A3'
                          ? pageTypes.a3.width
                          : pageTypes.a4.width
                      }
                    >
                      <Flex column>
                        <LayoutWorkingPad scale={1} pageType={page.layout?.pageType}>
                          {!!page?.layout?.blocks?.length &&
                            page?.layout.blocks.map((block: BlockType) => {
                              return (
                                <PageBlock
                                  selected={false}
                                  key={Math.random()}
                                  backgroundColor={block.backgroundColor}
                                  top={block.y}
                                  left={block.x}
                                  width={block.width}
                                  height={block.height}
                                  zIndex={block.layer}
                                />
                              );
                            })}
                        </LayoutWorkingPad>
                      </Flex>
                    </ScalingContainer>
                  </ScaleFlexContainer>
                </Flex>
              ))}
            </Flex>
          ) : (
            <em>No pages in this Chapter.</em>
          )}
        </Flex>

        <Flex column style={{ width: 355, maxHeight: '100vh', overflowY: 'auto' }}>
          {pagesLoaded ? (
            <>
              {!!pageData?.pages?.length &&
                pageData.pages.map((page: PageType) => {
                  return (
                    <Flex column key={page.id}>
                      <p>{page.name}</p>
                      <ScaleFlexContainer
                        pageType={page.layout?.pageType}
                        onClick={async () => {
                          setSelectedPages([...selectedPages, page]);
                        }}
                      >
                        <ScalingContainer
                          size={100}
                          float='CENTER'
                          defaultWidth={
                            page.layout?.pageType === 'A3'
                              ? pageTypes.a3.width
                              : pageTypes.a4.width
                          }
                        >
                          <Flex column>
                            <LayoutWorkingPad scale={1} pageType={page.layout?.pageType}>
                              {!!page?.layout?.blocks?.length &&
                                page?.layout.blocks.map((block: BlockType) => {
                                  return (
                                    <PageBlock
                                      selected={false}
                                      key={Math.random()}
                                      backgroundColor={block.backgroundColor}
                                      top={block.y}
                                      left={block.x}
                                      width={block.width}
                                      height={block.height}
                                      zIndex={block.layer}
                                    />
                                  );
                                })}
                            </LayoutWorkingPad>
                          </Flex>
                        </ScalingContainer>
                      </ScaleFlexContainer>
                    </Flex>
                  );
                })}
            </>
          ) : (
            <PageSpinner />
          )}
        </Flex>
      </Flex>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <UploadImageSideMenu
          onImageSelection={(v, imageId) => {
            setSelectedImage({ url: endpoints.BASE + v, id: imageId });
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </WithEditorMenu>
  );
};

export default ChapterEdit;
