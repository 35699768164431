import { createGlobalStyle } from 'styled-components';

import './styles/reset.css';
import './styles/base.css';
import 'react-responsive-modal/styles.css';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'pintura/pintura.css';
import 'intro.js/introjs.css';

const GlobalStyles = createGlobalStyle`
    * {
        font-family: 'Open Sans', sans-serif;
        box-sizing: border-box;
    }
    .DraftEditor-root span{
      font-family:inherit;
      font-size:inherit;
    }
    .PinturaNavGroup{
      transform:scale(1.5);
    }
    /* Modal button class to hide it */
    .displayNone{
      display:none;
    }
    .galleryOverlay{
      background-color:transparent;
    }
    .modalRight{
      float:right;
      position:relative;
      margin-right:3px;
      height:fit-content;
      padding:0 !important;
    }
    html, body, div, span, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    abbr, address, cite, code,
    del, dfn, em, img, ins, kbd, q, samp,
    small, strong, sub, sup, var,
    b, i,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section, summary,
    time, mark, audio, video {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        font-size:100%;
        vertical-align:baseline;
        background:transparent;

    }

    html {
        overflow-x: hidden;
    }

    body {
        line-height:1.5;
        overflow-x: hidden;
        min-height: 100vh;
        background-color: ${({ theme }) => theme.colors.isabelline50};
    }
    #root {
        min-height: 100vh;
    }



    .introjs-tooltip {
       background-color: ${({ theme }) => theme.colors.eggplant};
      &.introjs-floating {
        max-width: 400px;
        width: 400px;
        margin-left: -10vw !important;
      }
      &.introjs-top-left-aligned {
        max-width: 400px;
        width: 400px;
        left: 0 !important;
      }
      .introjs-skipbutton {
        color: white;
      }

      .introjs-tooltiptext {
        color: white;
        font-family: ${({ theme }) => theme.fontFamily.openSans};
        h2 {
          font-weight: bold;
          margin-bottom: 12px;
        }
      }
      .introjs-tooltipbuttons {
        border: none;
        a {
          font-family: ${({ theme }) => theme.fontFamily.openSans};
          color: white;
          border: none;
          background: none;
          text-shadow: none;
        }
      }
    }


    article,aside,details,figcaption,figure,
    footer,header,hgroup,menu,nav,section {
        display:block;
    }

    nav ul {
        list-style:none;
    }

    blockquote, q {
        quotes:none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content:'';
        content:none;
    }

    a {
        margin:0;
        padding:0;
        font-size:100%;
        vertical-align:baseline;
        background:transparent;

    }

    /* change colours to suit your needs */
    ins {
        background-color:#ff9;
        color:#000;
        text-decoration:none;
    }

    /* change colours to suit your needs */
    mark {
        background-color:#ff9;
        color:#000;
        font-style:italic;
        font-weight:bold;
    }

    del {
        text-decoration: line-through;
    }

    abbr[title], dfn[title] {
        border-bottom:1px dotted;
        cursor:help;
    }

    table {
        border-collapse:collapse;
        border-spacing:0;
    }

    /* change border colour to suit your needs */
    hr {
        display:block;
        height:1px;
        border:0;
        border-top:1px solid #cccccc;
        margin:1em 0;
        padding:0;
    }
input[type=range] {
  width: 100%;
  margin: 8px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: #d2e3f2;
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -8px;
  width: 20px;
  height: 20px;
  background: #58a4b0;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #d6e5f3;
}
input[type=range]::-moz-range-track {
  background: #d2e3f2;
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #58a4b0;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #cee1f1;
  border: 0;
}
input[type=range]::-ms-fill-upper {
  background: #d2e3f2;
  border: 0;
}
input[type=range]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #58a4b0;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: #d2e3f2;
}
input[type=range]:focus::-ms-fill-upper {
  background: #d6e5f3;
}
// how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}



    /* Modal Styling  */
    .react-responsive-modal-root {
        .react-responsive-modal-modal {
            border-radius: 8px;
            padding: 24px 0;
            max-width: 1200px;
            background-color: ${({ theme }) => theme.colors.babypowder};
            .react-responsive-modal-closeButton {
                outline: none;
            }
        }
    }

    /* Swiper Custom Styling */
    .swiper-button-next, .swiper-button-prev {
        color: ${({ theme }) => theme.colors.eggplant};
         &:hover {
                transform: scale(1.2);
                transition: 0.1s;
            }
        &::after {
            font-size: 25px;
            transition: 0.1s;

        }
    }

    .swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.colors.eggplant}
    }
    .swiper-slide {

    }
    .swiper-slide-active {
        display: inline-flex;
        transition: .1s;
        transform: scale(1.2);
        z-index: 200;
        margin-right: 0;
        img {
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        }
    }

`;

export default GlobalStyles;
