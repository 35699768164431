import React from 'react';
import styled from 'styled-components';

import NavBar from 'src/components/navigation/NavBar/NavBar';
import MenuBar from 'src/components/UI/menu/MenuBar';
import { TOOL_BAR_BORDER_HEIGHT, TOOL_BAR_HEIGHT } from 'src/app/config/constants';

const EditorMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: calc(100vh - ${TOOL_BAR_HEIGHT}px);
  flex: 1;
  border-top: ${TOOL_BAR_BORDER_HEIGHT}px solid #a0889a;
`;

const Children = styled.div`
  flex: auto;
`;

const WithEditorMenu: React.FC = ({ children }) => {
  return (
    <>
      <NavBar />
      <EditorMenuWrapper>
        <MenuBar />
        <Children>{children}</Children>
      </EditorMenuWrapper>
    </>
  );
};
export default WithEditorMenu;
