/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function GridViewIcon({ fill = '#60505E' }) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 14H8V8H14V14ZM6 14H0V8H6V14ZM14 6H8V0H14V6ZM6 6H0V0H6V6Z'
        fill={fill}
      />
    </svg>
  );
}
