import { gql } from 'graphql-request';

export const DeleteChapter = gql`
  mutation ($input: deleteChapterInput) {
    deleteChapter(input: $input) {
      chapter {
        id
        title
      }
    }
  }
`;
