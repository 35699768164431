import { HISTORY_ACTIONS, SUBS_TYPES } from 'src/app/config/constants';
// import { BlockType } from 'src/types/layout.interface';
import create from 'zustand';

type TActionTypes = keyof typeof HISTORY_ACTIONS;
type TSubkeys = keyof typeof SUBS_TYPES;
type TSubvalue = typeof SUBS_TYPES[TSubkeys];
export type THistoryItem = {
  pageID: string;
  chapterID: string;
  blockID: string | null;
  action: TActionTypes;
  previousContent: any;
};
export interface IUserData {
  userToken: string;
  userEmail: string;
  userID: string;
  firstName: string;
  lastName: string;
  customRole: 'editor' | 'normal' | '';
  shopifyId: string | number;
  history: THistoryItem[];
  onboarded: boolean;
  subscriptionType: TSubvalue;
}

export interface IAuthStore extends IUserData {
  isAuthenticated: boolean;
  setUserData: (data: IUserData) => void;
  setUserAuthenticated: (val: boolean) => void;
  clearStore: () => void;
}

const useAuthStore = create<IAuthStore>((set) => ({
  isAuthenticated: false,
  userToken: '',
  userEmail: '',
  userID: '',
  firstName: '',
  lastName: '',
  customRole: '',
  shopifyId: '',
  history: [],
  onboarded: false,
  subscriptionType: SUBS_TYPES.FREE,

  setUserData: (data: any) => {
    set(() => ({
      userToken: data.token,
      userEmail: data.email,
      userID: data.userID,
      firstName: data.firstName,
      lastName: data.lastName,
      customRole: data.customRole,
      shopifyId: data.shopifyId,
      history: data.history,
      onboarded: !!data.onboarded,
      subscriptionType: data.subscriptionType,
    }));
  },
  setUserAuthenticated: (val) => set(() => ({ isAuthenticated: val })),
  setHistory: (val) => set(() => ({ history: val })),
  clearHistory: () => set(() => ({ history: [] })),
  clearStore: () =>
    set(() => ({
      isAuthenticated: false,
      userToken: '',
      userEmail: '',
      userID: '',
      firstName: '',
      lastName: '',
      customRole: '',
      shopifyId: '',
      history: [],
      onboarded: false,
      subscriptionType: SUBS_TYPES.FREE,
    })),
}));

export default useAuthStore;
