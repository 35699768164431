export const mainColors = {
  babypowder: '#FFFCF9',
  isabelline: '#EBE3DE',
  isabelline50: '#F5EFEB',
  mountbatten: '#A0889A',
  mountbattenLight: 'rgba(81, 64, 76, 0.05)',
  eggplant: '#51404C',
  eggplant50: '#51404c9e',
  pewter: '#98B8BE',
  coral: '#FF8080',
  sand: '#DCD5CD',
  blackText: '#000000',
  white: '#FFFFFF',
  darkBrown: '#866147',
  twilight: '#D2D0D1',
  grayishOrange: '#D0C0B0',
  vividBlue: '#18a0fb',
  grayishPink: '#60505E',
  teal: '#58A4B0',
};

export const textColors = {
  h1: mainColors.eggplant,
  h2: mainColors.eggplant,
  h3: mainColors.eggplant,
  h4: mainColors.eggplant,
  h5: mainColors.eggplant,
  h6: mainColors.eggplant,
  p: mainColors.eggplant,
};

export const fontWeight = {
  thin: '100',
  extrLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  dark: '900',
};
export const fontFamilies = {
  montserrat: "'Montserrat', sans-serif",
  arial: 'Arial',
  petrona: 'Petrona',
  openSans: 'OpenSans',
};
export const pageTypes = {
  usLetter: { width: 612, height: 792 },
  a4: { width: 595, height: 842 },
  a3: { width: 1190, height: 842 },
};
export const fontSizes = {
  heading1: '38px',
  heading2: '28px',
  heading3: '22px',
  heading4: '20px',
  heading5: '18px',
  heading6: '16px',
  paragraph: '1rem',
  titleSize: '40px',
  xlg: '32px',
  lg: '24px',
  slg: '21px',
  md: '16px',
  sm: '12px',
  xsm: '10px',
};

export const deviceBreakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '410px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(min-width: ${deviceBreakpoints.mobileS})`,
  mobileM: `(min-width: ${deviceBreakpoints.mobileM})`,
  mobileL: `(min-width: ${deviceBreakpoints.mobileL})`,
  tablet: `(min-width: ${deviceBreakpoints.tablet})`,
  laptop: `(min-width: ${deviceBreakpoints.laptop})`,
  laptopL: `(min-width: ${deviceBreakpoints.laptopL})`,
  desktop: `(min-width: ${deviceBreakpoints.desktop})`,
  desktopL: `(min-width: ${deviceBreakpoints.desktop})`,
};

const defaultTheme = {
  colors: mainColors,
  textColors,
  fonts: fontFamilies,
  fontSizes,
  fontWeight,
  fontFamily: fontFamilies,
  breakpoints: deviceBreakpoints,
  pageType: pageTypes,
  devices,
};

export default defaultTheme;
