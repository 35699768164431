import { gql } from 'graphql-request';

export const DeletePage = gql`
  mutation ($input: deletePageInput) {
    deletePage(input: $input) {
      page {
        id
        name
      }
    }
  }
`;
