/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
export function PrevIcon() {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.650391 6.12695C0.386719 6.39062 0.386719 6.83008 0.650391 7.09375L6.33398 12.8066C6.62695 13.0703 7.06641 13.0703 7.33008 12.8066L8.00391 12.1328C8.26758 11.8691 8.26758 11.4297 8.00391 11.1367L3.49219 6.625L8.00391 2.08398C8.26758 1.79102 8.26758 1.35156 8.00391 1.08789L7.33008 0.414062C7.06641 0.150391 6.62695 0.150391 6.33398 0.414062L0.650391 6.12695Z"
        fill="#A0889A"
      />
    </svg>
  );
}
