const endpoints = {
  BASE: process.env.REACT_APP_API_ENDPOINT,
  GRAPHQL: `${process.env.REACT_APP_API_ENDPOINT}/v1/api`,
  LOGIN: `${process.env.REACT_APP_API_ENDPOINT}/auth/local`,
  LOGIN_GOOGLE: `${process.env.REACT_APP_API_ENDPOINT}/connect/google`,
  LOGIN_FACEBOOK: `${process.env.REACT_APP_API_ENDPOINT}/connect/facebook`,
  REGISTER: `${process.env.REACT_APP_API_ENDPOINT}/auth/local/register`,
  REGISTER_GOOGLE: `${process.env.REACT_APP_API_ENDPOINT}/connect/google`,
  REGISTER_GOOGLE_CALLBACK: `${process.env.REACT_APP_API_ENDPOINT}/auth/google/callback`,
  REGISTER_FACEBOOK: `${process.env.REACT_APP_API_ENDPOINT}/connect/facebook`,
  REGISTER_FACEBOOK_CALLBACK: `${process.env.REACT_APP_API_ENDPOINT}/auth/facebook/callback`,
  FORGOT_PASSWORD: `${process.env.REACT_APP_API_ENDPOINT}/auth/forgot-password`,
  RESET_PASSWORD: `${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password`,
  USERS: `${process.env.REACT_APP_API_ENDPOINT}/users`,
  UPDATE_PASSWORD: `${process.env.REACT_APP_API_ENDPOINT}/password`,
};

export default endpoints;
