import endpoints from 'src/app/config/endpoints';
import portrait_placeholder from 'src/theme/img/backgrounds/portrait_placeholder.jpg';

function parseImageURL(url: string | null, profilePicture?: boolean) {
  if (!url && profilePicture) return portrait_placeholder;
  if (!url) return 'https://via.placeholder.com/210x166';
  try {
    // eslint-disable-next-line
    new URL(url);
    return url;
  } catch (e) {
    return `${endpoints.BASE}${url}`;
  }
}

export default parseImageURL;
