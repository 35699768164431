import { lazy } from 'react';

import ChapterEdit from 'src/pages/guarded/PresetCreator/ChapterEdit/ChapterEdit';

import { ROUTES } from './constants';

// MAIN APP ROUTES
// const Landing = lazy(() => import('src/pages/public/LandingPage/LandingPage'));
const Login = lazy(() => import('src/pages/public/Login/Login'));
// const Register = lazy(() => import('src/pages/public/Register/Register'));
const ForgotPassword = lazy(
  () => import('src/pages/public/ForgotPassword/ForgotPassword')
);
const ResetPassword = lazy(() => import('src/pages/public/ResetPassword/ResetPassword'));
const EmailConfirmation = lazy(
  () => import('src/pages/public/EmailConfirmation/EmailConfirmation')
);
const PrivacyPolicy = lazy(() => import('src/pages/public/PrivacyPolicy/PrivacyPolicy'));
const SharedMagazine = lazy(
  () => import('src/pages/public/SharedMagazine/SharedMagazine')
);

// Guarded
const MainOverview = lazy(() => import('src/pages/guarded/overview/MainOverview'));
const EditMagazine = lazy(() => import('src/pages/guarded/magazine/EditMagazine'));
const MyProfile = lazy(() => import('src/pages/guarded/my-profile/MyProfile'));

// PRESET CREATOR ROUTES
const LayoutEditor = lazy(
  () => import('src/pages/guarded/PresetCreator/LayoutCreator/LayoutEditor')
);
const LayoutList = lazy(
  () => import('src/pages/guarded/PresetCreator/LayoutList/LayoutList')
);
const PageEdit = lazy(() => import('src/pages/guarded/PresetCreator/PageEdit/PageEdit'));
const PageList = lazy(() => import('src/pages/guarded/PresetCreator/PageList/PageList'));
const ChapterList = lazy(
  () => import('src/pages/guarded/PresetCreator/ChapterList/ChapterList')
);

const routes = [
  // { path: ROUTES.landing, component: Login, private: false },
  { path: ROUTES.login, component: Login, private: false },
  // { path: ROUTES.register, component: Register, private: false },
  { path: ROUTES.forgotPassword, component: ForgotPassword, private: false },
  { path: ROUTES.resetPassword, component: ResetPassword, private: false },
  { path: ROUTES.overview, component: MainOverview, private: true },
  { path: ROUTES.myProfile, component: MyProfile, private: true },
  { path: ROUTES.createLayout, component: LayoutEditor, private: true },
  { path: ROUTES.editLayout, component: LayoutEditor, private: true },
  { path: ROUTES.layoutList, component: LayoutList, private: true },
  { path: ROUTES.pageList, component: PageList, exact: true, private: true },
  { path: ROUTES.pageEdit, component: PageEdit, exact: false, private: true },
  { path: ROUTES.editMagazine, component: EditMagazine, exact: false, private: true },
  { path: ROUTES.chapterList, component: ChapterList, exact: true, private: true },
  { path: ROUTES.chapterEdit, component: ChapterEdit, exact: false, private: true },
  {
    path: ROUTES.sharedMagazine,
    component: SharedMagazine,
    exact: false,
    private: false,
  },
  {
    path: ROUTES.emailConfirmation,
    component: EmailConfirmation,
    exact: false,
    private: false,
  },
  { path: ROUTES.privacyPolicy, component: PrivacyPolicy, private: false },
];
export default routes;
